import { enablePageRefresh } from "../../../siteWidgetUtils"
import { FlowPlayerForInline } from "../flowPlayerForInline/flowPlayerForInline"
import { SiteVideoData } from "widgets/interfaces"
import { buildAdTag } from "../../videoPlayerAds"

interface Props {
    videoSource: string
    isYnetLive?: boolean
    onClose?: () => void
}

interface State {
    isHidden: boolean
    preRollAdPlayed: boolean
    isFullScreen: boolean
    isIntermediateScreen: boolean
}

export class FloatingVideoLivePlayer extends React.Component<Props, State> {
    static siteScriptName = "FloatingVideoLivePlayer"
    constructor() {
        super();
        this.state = {
            isHidden: false,
            preRollAdPlayed: false,
            isFullScreen: false,
            isIntermediateScreen: false,
        }
    }

    private playerRef;

    private onClose = () => {
        this.setState({ isHidden: true })
        this.props.onClose();
    }

    componentDidMount() {
        enablePageRefresh();
        if ( typeof window !== "undefined" && window.innerWidth < 1600 && !this.props.isYnetLive) {
            this.setState({ isHidden: true })
        }
        this.props.isYnetLive && document.addEventListener("fullscreenchange", this.onFullScreenChange)
    }

    componentWillUnmount() {
        this.props.isYnetLive && document.removeEventListener("fullscreenchange", this.onFullScreenChange)
    }

    private onFullScreenChange = () => {
        const isFullScreen = document.fullscreenElement !== null;

        if ( !isFullScreen && this.state.isFullScreen) {
            document && document.exitFullscreen();
            this.setState({ isFullScreen: false, isIntermediateScreen: false });
        }
    }

    private getFlowPlayerData = (): SiteVideoData => {
        const { videoSource } = this.props;
        return {
            mediaType: "MEDIA_VIDEO",
            url: videoSource,
            downGradeUrl: videoSource,
            poster: "",
            youtube_id: "",
            blockAbroadViews: false,
            isLive: true,
            isAutoPlaying: true,
            isPlayerBarVisible: true,
            isSoundButtonVisible: true,
            isMuteAvailable: true
        }
    }

    private handlePlayerLoaded = (player) => {
        const {isYnetLive} = this.props;
        const that = this;
        player.setOpts({ ima: null });
        !isYnetLive && player.setOpts({ muted: true });
        player.play();        
        setImmediate(() => {
            player.on("volumechange", function (e) { that.onStartAds(player, e) })
            player.ads.on("ad-completed", function () {
                that.setState({ preRollAdPlayed: true })
                !isYnetLive && player.setOpts({ muted: false });
                player.play();
            })
        })

        setImmediate(() => player.ads.on("ad-completed", function () {
            that.setState({ preRollAdPlayed: true })
            !isYnetLive && player.setOpts({ muted: false });
            player.play();
        }))
    }

    private onStartAds = (player, e) => {
        if (this.state.preRollAdPlayed) return;
        player.setOpts({ ima: { locale: "he", ads: [] } });
        const adTag = buildAdTag("Preroll", false, true, "fpn");
        player.ads.requestAd(adTag);
    }

    private onToggleFullscreen = (isFullScreen: boolean) => {
        if (isFullScreen) {
            document && document.exitFullscreen();
            this.setState({ isFullScreen: false, isIntermediateScreen: false });
        } else {
            this.playerRef.requestFullscreen();
            this.setState({ isFullScreen: true });
        }
    }

    private onToggleIntermediateScreen = () => {
        const { isIntermediateScreen, isFullScreen } = this.state;
        if (isFullScreen) {
            this.onToggleFullscreen(true);
            this.setState({ isIntermediateScreen: true });
        }else if(isIntermediateScreen) {
            this.setState({ isIntermediateScreen: false});
        }else {
            this.setState({ isIntermediateScreen: true});
        }
    }

    public render() {
        const { isHidden, isFullScreen, isIntermediateScreen } = this.state;
        const { isYnetLive } = this.props;
        if (isHidden) return null;
        const isLargeScreen =  typeof window !== "undefined" && window.innerWidth >= 1600;
        const fullScreenBtnClass = `custom-full-screen ${isFullScreen ? "open" : "close"}`;
        const intermediateScreenBtnClass = `custom-intermediate-screen ${isIntermediateScreen ? "open" : "close"} ${!isLargeScreen  ? "none" : ""}`;
        const controlBarWidth = isFullScreen || isIntermediateScreen || !isLargeScreen ? "fullWidth" : "";
        const floatingPlayerClass = `YITFloatingPlayer is-sticky ${isIntermediateScreen || !isLargeScreen ? "intermediate" : ""} ${controlBarWidth}`;

        return (
            <>
                <div className={floatingPlayerClass} ref={ref => this.playerRef = ref}>
                    <div className="YITFloatingPlayerCloseButton" onClick={() => this.onClose()}></div>
                    {isYnetLive &&
                        <>
                            <div className="custom-controllers">
                                <div className={fullScreenBtnClass} onClick={() => this.onToggleFullscreen(isFullScreen)}></div>
                                <div className={intermediateScreenBtnClass} onClick={this.onToggleIntermediateScreen}></div>
                            </div>
                        </>
                    }
                    <FlowPlayerForInline
                        data={this.getFlowPlayerData()}
                        applyStickyness={false}
                        hideVideoAds
                        onPlayerLoaded={this.handlePlayerLoaded}
                    />
                </div>
                {isIntermediateScreen && <div className="intermediate-overlay"></div>}
            </>
        )
    }
}



