

interface CalcalistPaywallCheckoutFinishProps {

}


const CalcalistPaywallCheckoutMobileFinish = (props: CalcalistPaywallCheckoutFinishProps) => {

    return (
        <div className="CalcalistPaywallCheckoutFinish">
            <h1 className="title">הרכישה הושלמה!</h1>
            <div className="main-container">
                <div className="finish-logo"></div>
                <div className="finish-text">
                    תודה שעשיתם מינוי לעיתון הדיגיטלי של כלכליסט.
                    מוזמנים לעבור למהדורה וליהנות מתוכן מעולה!
                </div>
                <button className="finish-button">לקריאת המהדורה</button>
            <div className="checkout-finish-logo"></div>
            </div>
        </div>
    );
};

export default CalcalistPaywallCheckoutMobileFinish;