var clickOutside = require("react-click-outside")
import * as classNames from "classnames"
import { isWcmEditor } from "config/serverConfig"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { DateDisplay } from "../../../../commonComponents/inline/dateDisplay/dateDisplay"
import { AccordionSocialsShareComponenta } from "../../../../siteWidgets/accordion/accordionSocialsShareComponenta"
import { getTranslation } from "../../autoTickerRowWidget/autoTickerRowTranslations"
import { getNewlinkTargetProperties } from "../../ynetShops/data/ynetshopsData";

interface TickerItemSlotProps {
    title: string
    flashDate: Date
    author?: string
    articleId?: string
    isAuto?: boolean
    flashText: string
    tabHref: string
    isFirstItem?: boolean
    onToggleOpenItem: (articleId: string) => void
    onOpenItem: (articleId: string) => void
    onOpenShareItemButtons: (articleId: string) => void
    link: string
    roof: string
    newItem?: boolean
    openedItemId: string
    openedShareItemId: string
    isFirstInterval: boolean
    shouldDisplayLoader: boolean
    isShortMode?: boolean
    lang:string
}

interface TickerItemSlotState {
    isFirstRender: boolean
}

@clickOutside
export class RowTickerItemSlot extends React.Component<TickerItemSlotProps, TickerItemSlotState>{
    constructor(props) {
        super(props);
        this.state = {
            isFirstRender: true,        
        }
    }

    private secondsDiff = (d1, d2) => {
        let secDiff = Math.floor((d1 - d2) / 1000);
        let minutesDiff = Math.floor(secDiff / 60);
        return minutesDiff;
    }

    private mouseLeaveItem = () => {
        setTimeout(() => {
            if (this.props.openedItemId === this.props.articleId) {
                this.props.onToggleOpenItem(this.props.articleId)
            }
            this.props.onOpenShareItemButtons(null)
        }, 500);
    }


    private openShare = (articleId) => {
        event.preventDefault();
        this.props.onOpenShareItemButtons(articleId)
    }


    public render() {
        const { title, flashDate, flashText, articleId, tabHref, author, isFirstItem, link, roof, newItem, openedShareItemId, openedItemId, isFirstInterval, shouldDisplayLoader, isShortMode, lang } = this.props

        const href = `${tabHref}#${articleId}`;
        const shouldOpenItem = openedItemId === articleId && flashText.length > 0;
        const isShareOpened = openedShareItemId === articleId;
        const langToUse = lang.slice(0, 2);
        const newDate = getTranslation(langToUse, "Now");
        const text = author && author.length > 0 ? `${flashText} (${author})` : flashText;
        const isAuthor = author && author.length > 0;
        let slotHeight = isShortMode ? "100px" : "126px";
        return (
            <div className={classNames(
                "slotView",
                {
                    "inEditMode": isWcmEditor(),
                    "emptySlot": title.length === 0,
                    "now": newItem,
                    "firstItem": isFirstItem && newItem,
                    "opened": shouldOpenItem,
                    "newItem":newItem && isShortMode,
                }
            )}
                style={{ height: shouldOpenItem ? "auto" : slotHeight}}
                onMouseLeave={this.mouseLeaveItem}

            >
                <div className="toggleElm" onClick={() => this.props.onToggleOpenItem(articleId)}></div>

                {!isShortMode && <div className="topRow" id={articleId + "_flashElem"}>
                    <div className={classNames("flashDate", { "withWidth": title.length === 0 })}>
                        <span>
                            {newItem ? newDate : <DateDisplay date={flashDate} isMinutesAgo={true} />}
                        </span>
                    </div>
                    {roof && roof.length > 0 && <div className="roofTitle">{roof}</div>}
                </div>}

                <div className="mainArea" id={articleId + "_flashElem"}>
                    <span className="itemTitle"

                        children={title} >
                        {shouldDisplayLoader ? <img className={"newTickerLoader"} src={"https://www.ynet.co.il/images/NewsFlash/NF_Desktop_Loader.gif"} /> : <> {roof && roof.length > 0 && isShortMode && <div className="roofTitle">{roof}</div>}<div dangerouslySetInnerHTML={{ __html: title }} /></>}
                    </span>

                    <div className={`text ${shouldOpenItem ? "vissible" : "hidden"}`}>
                        {flashText}
                        {isAuthor && <br />}
                        {isAuthor && `(${author})`}
                    </div>


                </div>
                <div className="buttonsArea">
                    <span className={`openMoreButton ${shouldOpenItem ? "opened" : ""}`} ></span>
                    <span className={`shareBtn ${isShareOpened ? "opened" : ""}`} onClick={() => this.openShare(articleId)}></span>
                    <AccordionSocialsShareComponenta className={isShareOpened ? "visible" : "hidden"} shareUrl={link} title={title} articleId={articleId} isNew={true} />
                    {isShortMode && <div className={classNames("flashDate", { "withWidth": title.length === 0 })}>
                        <span>
                            {newItem ? newDate : <DateDisplay date={flashDate} isMinutesAgo={true} />}
                        </span>
                    </div>}
                </div>

            </div>
        )
    }
}