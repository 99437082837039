import { DateDisplay } from "../../../../commonComponents/inline/dateDisplay/dateDisplay";
import { useState, useRef, useEffect } from "react"
import { SiteVideoMedia } from "../../../../siteWidgets/siteMedia";
import { getFrontImageUrl } from "../../../../../front/staticServerRendering/frontImage";
import { ImageSize } from "../../../../../constants/imageSizes";
import { MediaContentTypes } from "../../../../../constants/contentTypes";
import { SiteVideoData } from "../../../../interfaces";
import { openFacebookShare, openWhatsAppShare, openTwitterShare } from "../../../../widgetsUtils";
import { SimpleAudioPlayer } from "../../simpleAudioPlayer/simpleAudioPlayer";
import { NewsFlashItemType } from "./radionasNewsFlashComponent";

interface NewsFlashItemProps {
    article: NewsFlashItemType
    audioDomain: string
    isTogglesOpen: boolean
    isMobileWeb: boolean
}

export const NewsFlashItem = (props: NewsFlashItemProps) => {

    const { article, isTogglesOpen, audioDomain, isMobileWeb } = props
    const [toggleState, setToggleState] = useState(isTogglesOpen)
    const [textHeight, setTextHeight] = useState(null)
    const accordionWrapperRef = useRef<HTMLDivElement>()
    const articleBodyTextStyles = toggleState ? { maxHeight: textHeight } : { maxHeight: 0 }

    useEffect(() => {
        setToggleState(isTogglesOpen)
    }, [isTogglesOpen])

    useEffect(() => {
        setTextHeight(accordionWrapperRef.current.scrollHeight)
    }, [])

    const onToggleClick = () => {
        setToggleState(!toggleState)
    }

    const getArticleMedia = () => {
        switch (article.specialData.mediaLinkType!==undefined && article.specialData.mediaLinkType) {
            case "image":
                return <img src={getFrontImageUrl({ publishedLink: article.imageUrl }, { imageSize: ImageSize.large })} />

            case "video":
                const videoData: SiteVideoData = {
                    mediaType: MediaContentTypes.MEDIA_VIDEO,
                    url: article.video[0].main_link,
                    downGradeUrl: article.video[0].low_res_link,
                    poster: article.video[0].posterSrc,
                    isPlayerBarVisible: article.specialData.videoBehaivior.isPlayerBarVisible,
                    isAutoPlaying: false,
                    isLoopPlaying: article.specialData.videoBehaivior.isLoopPlaying,
                    isMuteAvailable: article.specialData.videoBehaivior.isMuteAvailable,
                    isSoundButtonVisible: article.specialData.videoBehaivior.isSoundButtonVisible,
                    isBigVideo: true,
                    youtube_id: article.video[0].youtube_id,
                    mediaId: article.video[0].mediaVideoId,
                    blockAbroadViews: article.video[0].blockAbroadViews,
                    credit: article.video[0].credit,
                }

                return <SiteVideoMedia data={videoData} videoSettings={{ ...article.specialData.videoSettings, isVideoLightbox: false }} />

            case "audio":
                return <SimpleAudioPlayer src={`${audioDomain}${article.specialData.audioNewsflashData.filePath}`} duration={article.specialData.audioNewsflashData.durationSeconds} />

            default:
                break;
        }
    }

    const handleShareBtnClick = () => {
        if (isMobileWeb) {
            openMobileShare()
        }
    }

    const openMobileShare = async () => {
        const shareData = { url: article.publishedLink, title: article.title }
        try {
            // @ts-ignore 
            await navigator.share(shareData)
        } catch (err) {
            console.log("error with sharing function", err);
        }
    }

    return (
        <div className="news-flash-item">
            <div className="item-content-wrapper">
                <div className="flash-item-header">
                    <div className="author-details">
                        {article.firstAuthorImage ?
                            <img className="author-image" src={article.firstAuthorImage} />
                            :
                            <div className="author-image default-author-image"></div>
                        }
                        <div className="author-name">{article.author}</div>
                    </div>

                    <DateDisplay date={article.date} />
                </div>

                <div className="flash-item-body">
                    {getArticleMedia()}

                    <div className="article-title">{article.title}</div>

                    <div className="article-body-text" style={articleBodyTextStyles} ref={accordionWrapperRef as any}>
                        {article.text}
                    </div>
                </div>

                <div className="flash-item-footer">
                    <div className="share-menu" onClick={handleShareBtnClick}>
                        {!isMobileWeb && <div className="twitter icon" onClick={() => openTwitterShare(article.publishedLink)}></div>}
                        {!isMobileWeb && <div className="whatsapp icon" onClick={() => openWhatsAppShare(article.publishedLink)}></div>}
                        {!isMobileWeb && <div className="facebook icon" onClick={() => openFacebookShare(article.publishedLink, article.title)}></div>}
                    </div>

                    {isMobileWeb && <div className={`toggle-btn icon ${toggleState ? "open" : ""}`} onClick={onToggleClick}></div>}
                </div>
            </div>

            {!isMobileWeb && <div className={`toggle-btn icon ${toggleState ? "open" : ""}`} onClick={onToggleClick}></div>}
        </div>
    )
}
