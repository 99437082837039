import * as classNames from "classnames"
import { CSSTransitionGroup } from "react-transition-group";

import { MediaContentTypes } from "constants/contentTypes"
import { GalleryPaging } from "../commonComponents/inline/galleryPaging/galleryPaging"
import { SiteMediaData, SiteImageData } from "../interfaces"
import { VideoSettingsData } from "widgets/commonDataObjects/interfaces"
import { SiteMedia } from "./siteMedia";
import { GalleryViewType } from "../commonComponents/base/basicMediaCarousel"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { LabelText } from "../defenitions/ynet1280/topStory/components/labelText";

interface SiteMediaCarouselProps {
    medias: SiteMediaData[]
    height: number
    width: number
    arrowClassLeft?: string
    arrowClassRight?: string
    galleryViewOption?: GalleryViewType
    intervalTime?: number
    videoSettings?: VideoSettingsData
    title?: string
    itemLinkUrl?: string
    titlesContainerElementId?: string
    linkTarget: LinkTargetProperties
    shouldAddArticleAutoplayIndicator?: boolean
    isImageLinkable?: boolean
    articleId?: string
    galleryRatio?: string
    isDataTbLink?: boolean
    hasTextLabel?:boolean
}

interface SiteMediaCarouselState {
    index: number;
}
export class SiteMediaCarousel extends React.Component<SiteMediaCarouselProps, SiteMediaCarouselState>{
    public static siteScriptName = "SiteMediaCarousel";
    constructor(props) {
        super(props);
        this.state = { index: 0 }
    }

    public static defultProps: Partial<SiteMediaCarouselProps> = {
        galleryViewOption: "arrows",
        intervalTime: 0,
        videoSettings: {
            isVideoLightbox: false,
            hideVideoAds: false,
        }
    }

    private showPrevMedia = () => {
        this.setState(state => (state.index > 0 ? {
            index: state.index - 1
        } : state))
        /*   if (this.props.intervalTime !== 0) {
               clearInterval(this.autoGalleryInterval)
           }*/
    }

    private showNextMedia = () => {
        this.setState(state => (state.index < this.props.medias.length - 1 ? { index: state.index + 1 } : state))
        /* if (this.props.intervalTime !== 0) {
             clearInterval(this.autoGalleryInterval)
         }*/
    }

    private showSelected = (i: number) => {
        if (this.props.intervalTime !== 0) {
            clearInterval(this.autoGalleryInterval)
        }
        this.setState({ index: i })
    }

    private autoGalleryInterval: any;
    private startAutoGallery = () => {
        const { intervalTime, medias } = this.props
        const self = this;
        if (typeof (intervalTime) === "number") {
            if (intervalTime > 0) {
                this.autoGalleryInterval = setInterval(() => {
                    this.setState((prevState: SiteMediaCarouselState, props: SiteMediaCarouselProps) => {
                        let newIndex = ++prevState.index;
                        if (newIndex >= props.medias.length) newIndex = 0;
                        return { index: newIndex }
                    });
                }, intervalTime * 1000);
            }
            else {
                clearInterval(this.autoGalleryInterval)
            }
        }
    }

    private hideTitlesWhenVideoPlayStarts = () => {
        if (this.props.titlesContainerElementId) {
            const titlesContainerElement = document.getElementById(this.props.titlesContainerElementId) as HTMLElement
            if (titlesContainerElement) {
                titlesContainerElement.style.visibility = "hidden";
            }
        }
    }

    private getRatio = () => {
        const { galleryRatio } = this.props;

        switch (galleryRatio) {
            case "16:9":
                return "56.25%"
            case "4:3":
                return "75%"
            case "2:3":
                return "150%"
            case "1:1":
                return "100%"
            case "9:16":
                return "178%"
            default:
                break;
        }
    }

    componentDidMount() {
        if (this.props.medias.length > 1) {
            this.startAutoGallery();
        }

    }
    componentDidUpdate(_, prevState: SiteMediaCarouselState) {
        if (this.state.index !== prevState.index && this.props.medias[this.state.index].mediaType === (MediaContentTypes.MEDIA_VIDEO || MediaContentTypes.MEDIA_LIVE)) {
            window.YitVideo && window.YitVideo.InfrastructureReInitialize();
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.state.index !== nextState.index) {
            if (this.props.titlesContainerElementId) {
                const titlesContainerElement = document.querySelector(`#${this.props.titlesContainerElementId}`) as HTMLElement
                if (titlesContainerElement) {
                    titlesContainerElement.style.visibility = "visible"
                }
            }
        }
    }

    public render() {
        const { medias, arrowClassRight, arrowClassLeft, height, width,hasTextLabel , 
            galleryViewOption, videoSettings, title, itemLinkUrl, linkTarget, shouldAddArticleAutoplayIndicator, isImageLinkable, articleId, isDataTbLink } = this.props
        const leftArrow = classNames("left galleryArrow", arrowClassLeft)
        const rightArrow = classNames("right galleryArrow", arrowClassRight)
        const { index } = this.state
        let media = medias[index] as SiteMediaData;
        if (!media) {
            media = {
                mediaType: MediaContentTypes.MEDIA_IMAGE,
                url: undefined,
                title: "",
                alt: "",
                credit: "",
                ariaHidden: true,
                className: undefined,
            }
        }
        const isImageClickable = medias.filter(obj => obj.mediaType === "MEDIA_VIDEO").length === 0 && isImageLinkable;
        const oneItem = medias.length > 1 ? "moreThenOneItemCarousel" : ""
        const isArrow = galleryViewOption === "arrows" || galleryViewOption === "arrowsAndPaging"
        const isPaging = galleryViewOption === "paging" || galleryViewOption === "arrowsAndPaging"
        return (
            <div className={`MediaCarousel ${oneItem}`} style={{ height, width }} >
                <div className="mediaItems" onClick={() => clearInterval(this.autoGalleryInterval)}>
              {hasTextLabel && <LabelText className={"mediaItemLabel"} 
                    labelText={(media as SiteImageData).labelText} mediaIconPlace={(media as SiteImageData).textPlace} onLabelTextChange={()=>null} /> }
                    {arrowClassLeft && isArrow && medias.length > 1 && <button
                        className={classNames(leftArrow, { "disable": (index === 0) })} onClick={this.showPrevMedia}
                        title="left"
                        aria-label="left"
                        disabled={index === 0} />}
                    <div className="aspectRatioBox" style={{ paddingTop: this.getRatio() }}>
                        <CSSTransitionGroup transitionName="media" transitionEnterTimeout={500} transitionLeave={false} >
                            <SiteMedia
                                itemLinkUrl={itemLinkUrl}
                                linkTarget={linkTarget}
                                shouldAddArticleAutoplayIndicator={shouldAddArticleAutoplayIndicator}
                                onPlayStarted={this.hideTitlesWhenVideoPlayStarts}
                                isImageLinkable={isImageLinkable}
                                data={media}
                                height={height}
                                width={width}
                                key={media.url}
                                isDataTbLink={isDataTbLink}
                                videoSettings={videoSettings}
                                allMedias={medias}
                                articleId={articleId} />
                        </ CSSTransitionGroup >
                    </div>
                    {medias.length > 1 && isPaging && <GalleryPaging selectedIndex={index} onSelect={this.showSelected} data={medias} />}
                    {arrowClassRight && isArrow && medias.length > 1 && <button
                        title="right"
                        aria-label="right"
                        className={classNames(rightArrow, { "disable": (index === medias.length - 1) })}
                        onClick={this.showNextMedia} disabled={index === medias.length - 1} />}
                </div>
            </div>
        )
    }
}

