
const apiPrefix = "/auth";

//sign up
export const postPaywallSignedUp = (recaptcha: string) => addRecaptchaToUrl(`${apiPrefix}/public/signup/otp`, recaptcha);
export const postPaywallSignedUpEnd = (recaptcha: string) => addRecaptchaToUrl(`${apiPrefix}/public/signup/otp/end`, recaptcha);

//sign in
export const postPaywallSignedIn = (recaptcha: string) => addRecaptchaToUrl(`${apiPrefix}/public/signIn/otp`, recaptcha);
export const postPaywallSignedInEnd = (recaptcha: string) => addRecaptchaToUrl(`${apiPrefix}/public/signIn/otp/end`, recaptcha);
export const postPaywallAuthGoogleSignIn = () => `${apiPrefix}/public/signIn/tp/google`
export const postPaywallAuthAppleSignIn = () => `${apiPrefix}/public/signIn/tp/apple`
export const postPaywallAuthThirdPartyEndSignIn = () => `${apiPrefix}/public/signIn/tp/end`

//log out
export const postPaywallLogOut = () => `${apiPrefix}/restricted/logout/device`

//details
export const updatePersonalCustomerDetails = () => `${apiPrefix}/restricted/details/personalCustomerDetails/update`
export const getPersonalCustomerDetails = () => `${apiPrefix}/restricted/details/personalCustomerDetails/get`
export const getPaywallAccessData = () => `${apiPrefix}/restricted/details/get/access`
export const getPaywallReadAllowed = (claim: string, resource: string) => `${apiPrefix}/restricted/resource/freeRead/test/${claim}/${resource}`


//purchase
export const postPaywallPurchaseProduct = () => `${apiPrefix}/restricted/buy/product`

function addRecaptchaToUrl(url: string, recaptcha: string) {
    const urlObj = new URL(url, document.location.href);
    urlObj.searchParams.set("recaptcha", recaptcha);
    return urlObj.href;
}
