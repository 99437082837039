import { SiteItemData } from "../../../../interfaces";
import { isWcmEditor } from "config/serverConfig";
import { VideoGalleryVerticalDesktop } from "./videoGalleryVerticalDesktop";

export interface categoryObj {
    id: string
    categoryColor: string
    name: string
}

interface MagazineVideoGalleryVerticalComponentaProps {
    videoList: SiteItemData[]
    isTabVisible: boolean
    tabTitleText: string
    repeatAds: number
    maxAds: number
    firstAdPlace: number
    category: categoryObj
    domain: string
    showPrerollAds: boolean
}


export class MagazineVideoGalleryVerticalComponenta extends React.Component<MagazineVideoGalleryVerticalComponentaProps, {}>{
    public static siteScriptName = "MagazineVideoGalleryVerticalComponenta";

    public render() {
        const { videoList, isTabVisible, tabTitleText, repeatAds, maxAds, firstAdPlace, category, domain, showPrerollAds } = this.props;
        const componentClass = `magazineVideoGalleryVerticalComponenta ${isWcmEditor() ? "editMode" : ""}`;
        
        return (
            <div className={componentClass}>
                <VideoGalleryVerticalDesktop
                    videoList={videoList}
                    isTabVisible={isTabVisible}
                    tabTitleText={tabTitleText}
                    repeatAds={repeatAds}
                    maxAds={maxAds}
                    firstAdPlace={firstAdPlace}
                    category={category}
                    domain={domain}
                    showPrerollAds={showPrerollAds}/>
            </div>
        )

    }
}
