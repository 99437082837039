import { ArticleTypes, VideoCooperationSettings } from "articlePage/stateInterfaces"
import { ArticleQuotePosition, ArticleBodySpecialBlocks } from "../articlePage/stateInterfaces"
import { SiteConfigFileEditorPlatforms, SiteConfigFileEditorFileEntities } from "../siteConfigFileEditor/siteConfigFileEditorInterfaces";

export interface SettingsProps {
    siteTitleTemplate?: string
    siteTitle?: string
    topMenuItemsList?: TopMenuItem[]
    baseUrl?: string
    statusList?: StatusItems[]
    talkbackStatusList?: StatusItems[]
    articleTypes?: ArticleTypes[]
    talkbackFilterType?: TalkbackFilterItems[]
    welcomePageMenuList?: MenuList[]
    articleTypesDictionery: { [articleType: string]: ArticleTypes }
    allArticleTypesDictionery?: { [articleType: string]: ArticleTypes }
    defaultTitleColorPickerColors: string[]
    articleQuotePosition: ArticleQuotePosition
    articleBodySpecialBlocks: ArticleBodySpecialBlocks
    articleMinTags: number
    videoCooperationSettings?: VideoCooperationSettings[]
    articleClassifications?: Array<{value:string, label:string, isDefault?:boolean}>
    defaultArticleClassification?:string
    siteConfigFileEditorPlatforms?: SiteConfigFileEditorPlatforms[] 
    siteConfigFileEditorFiles?: SiteConfigFileEditorFileEntities[]
}

type OnSettingsChangedCallbackType = () => void;
var onSettingsChangeCallback;
var settings: Partial<SettingsProps> = {};

export function update(newSettings: SettingsProps) {
    Object.assign(settings, newSettings);
    if (onSettingsChangeCallback) {
        onSettingsChangeCallback();
    }
}

export function registerOnSettingsChangeCallback(callback: OnSettingsChangedCallbackType) {
    onSettingsChangeCallback = callback;
}

export default settings;