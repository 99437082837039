import { PaywallAuthErrorCodes, PremiumPaywallSignModes } from "../../../../../../global/paywall/paywallInterfaces";


interface PremiumCalcalistPaywallDesktopSigninEmailProps {
  email: string
  setEmail: (email: string) => void
  setMode: (mode: PremiumPaywallSignModes) => void
  loginWithGoogle: () => void
  loginWithApple: () => void
  sendMail: () => void
  authError: string
}

export const PremiumCalcalistPaywallDesktopSigninEmail = (props: PremiumCalcalistPaywallDesktopSigninEmailProps) => {

  const { email, setEmail, loginWithApple, loginWithGoogle, setMode, sendMail, authError } = props

  const handleMailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSendMail = () => {
    sendMail()
  }

  const errorMessagesFront: Partial<Record<PaywallAuthErrorCodes, string>> = {
    [PaywallAuthErrorCodes.INVALID_EMAIL]: "כתובת המייל אינה תקינה",
  };

  const errorMessagesServer: Partial<Record<PaywallAuthErrorCodes, string>> = {
    [PaywallAuthErrorCodes.FAILED_TO_SEND_OTP]: "אירעה שגיאה במערכת, אנא נסו שוב מאוחר יותר",
  }

  return (
    <div className="premium-calcalist-paywall-desktop-signin-email">
      <div className="digital-edition-label">המהדורה הדיגיטלית</div>
      <h1 className="title">כניסה</h1>
      <div className={`email-signin ${authError && authError === PaywallAuthErrorCodes.INVALID_EMAIL ? 'error' : ''}`}>
        <input value={email} onChange={handleMailChange} type="email" placeholder="הקלידו כתובת מייל" />
        <div className="reset-icon" onClick={() => setEmail('')}></div>
        {authError && <p className="error-text" dangerouslySetInnerHTML={{ __html: errorMessagesFront[authError] }}></p>}
      </div>
      <div className="buttons-container">
        {/* <button className="button-base back" onClick={() => setMode("signUp")}>חזרה</button> */}
        <button className="button-base continue" onClick={handleSendMail}>המשך</button>
      </div>
      {authError && <p className="error-text" dangerouslySetInnerHTML={{ __html: errorMessagesServer[authError] }}></p>}
      <div className="separator">
        <span className="text">או התחברו באמצעות</span>
      </div>
      <div className="social-signin">
        <button className="social-button google-btn" onClick={loginWithGoogle}><span>גוגל</span></button>
        <button className="social-button apple-btn" onClick={loginWithApple}><span>אפל</span></button>
      </div>
      <div className="signup">
        <div>אין לך חשבון? <a href="#" onClick={() => setMode("signUp")}>הרשמה</a></div>
      </div>
      <div className="newspapper-users" >
        <div><span>מנויים לעיתון</span> <span className="calcalist-label"></span>?</div>
        <div>הזינו את המייל שלכם בשדה למעלה, וכנסו למהדורה הדיגיטלית</div>
      </div>
      <div className="bottom-logo"></div>
    </div>
  )
}
