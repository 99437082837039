require('./calcalist_site_calcalistPaywallLoginComponenta.less');
import { HyperLinkBuilder } from "../../../commonComponents/inline/hyperLink";
import { LinkTargetProperties } from "../../../../global/components/linkSettings/interfaces"
import { useState, useEffect } from "react"
import * as axios from "axios"
import {postPaywallLogOut } from "../../../../global/paywall/paywallApiUrls";
import { getCurrentUser, removeCurrentUser } from "../../../../global/paywall/paywallUtils";

interface CalcalistUser {
    displayName: string 
}
export interface CalcalistMenuItem {
    id: string
    name: string
    link: string
    catId: string
    url: string
    targetLink?: LinkTargetProperties
}

interface CalcalistLoginProps {
    menuItems?: CalcalistMenuItem[]
    subscribeBtnLink?: { link: string, linkTarget: LinkTargetProperties };
}
export const CalcalistPaywallLoginComponenta = (props: CalcalistLoginProps) => {
    const [user, setUser] = useState<CalcalistUser>(null);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const { menuItems } = props;


    useEffect(() => {
            const currentUser = getCurrentUser()
            setUser(currentUser);
    }, [])


    const getUsernameFromEmail = (email: string): string => {
        return email.split('@')[0];
    }

    const handleToggleMenu = () => {
        setIsMenuVisible(!isMenuVisible);
    }

    const getUserLabel = () => {
        let label = '';
        if (user) {
            label = user.displayName;
            if (user.displayName.includes('@')) {
                label = getUsernameFromEmail(user.displayName);
            }
            return <div onClick={handleToggleMenu}>שלום, <span className="bold">{label}</span></div>;
        }
        return <div>כניסה/הרשמה</div>;
    };

    const handleDisconectUser = async () => {
        await axios.post(postPaywallLogOut());
        removeCurrentUser();
        window.location.reload();
    }

    const handlePopup = () => {
        setIsPopupVisible(!isPopupVisible);
        document.body.style.overflowY = isPopupVisible ? 'auto' : 'hidden';
        document.body.classList.toggle('popup');
    }

    return (
        <div className="CalcalistPaywallLoginComponenta">
            <ul className="header-list">
                <li className="header-item">
                    <a href="https://www.calcalist.co.il/redmail" className="header-link red-text">
                        <div alt="RedMail" className="header-icon redmail"></div>
                        <div>דואר האדום</div>
                    </a>
                </li>
                <li className="header-item">
                    <a href="https://www.calcalist.co.il/conferences" className="header-link">
                        <div alt="Calendar" className="header-icon conference"></div>
                        <div>ועידות</div>
                    </a>
                </li>
                <li className="header-item">
                    <a href="#" className="header-link">
                        <div alt="Newspaper" className="header-icon newspaper"></div>
                        <div>המהדורה הדיגיטלית</div>
                    </a>
                </li>
                <li className="separator"><span></span></li>
                <li className="header-item">
                    <a href="#" className="header-link user">
                        <div alt="User" className="header-icon user"></div>
                        {getUserLabel()}
                    </a>
                </li>
            </ ul>
            {isMenuVisible &&
                <div className="CalcalistPaywallLoginComponentaUserMenu">
                    <div className="menu-item" onClick={() => this.handleMenuItem("ניהול חשבון")}>
                        <a href="#">לרכישת מנוי</a>
                    </div>
                    <div className="menu-item" onClick={() => this.handleMenuItem("ניהול חשבון")}>
                        <a href="#">שירות לקוחות</a>
                    </div>
                    <div className="menu-item" onClick={() => this.handleMenuItem("ניהול חשבון")}>
                        <a href="#">ניהול חשבון</a>
                    </div>
                    {menuItems.map((item, index) => {
                        return (
                            <div className="menu-item" key={index} id={item.id} onClick={() => this.handleMenuItem(item.name)} >
                                <HyperLinkBuilder href={item.link} linkTarget={item.targetLink}>
                                    {item.name}
                                </HyperLinkBuilder>
                            </div>
                        )
                    })}
                    <div onClick={handlePopup} className="menu-item">התנתקות</div>
                </div>}

            {isPopupVisible &&
                <>
                    <div className="CalcalistPaywallLoginComponentaOverlay"></div>
                    <div className="CalcalistPaywallLoginComponentaPopup">
                        <div className="inner-container">
                            <div className="logo-container">
                                <div className="digital-edidion-label">המהדורה הדיגיטלית</div>
                                <div className="disconnect-logo"></div>
                            </div>
                            <div className="text">
                                האם אתם בטוחים שברצונכם
                                להתנתק מהמשתמש שלכם?
                        </div>
                            <div className="buttons-contianer">
                                <div onClick={handlePopup} className="button back">חזרה</div>
                                <div onClick={handleDisconectUser} className="button action">כן, התנתק</div>
                            </div>
                        </div>
                    </div>
                </>
            }

        </div>
    )
}
CalcalistPaywallLoginComponenta.siteScriptName = "CalcalistPaywallLoginComponenta"

