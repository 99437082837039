import { useState, useRef } from 'react'
import { PaywallAuthErrorCodes } from "../../../../../../global/paywall/paywallInterfaces";

export interface PremiumCalcalistPaywallDesktopSigninPasswordProps {
  password: string
  email: string
  setPassword: (password: string) => void
  onContinue: () => void
  onBack: () => void
  sendMail: () => void
  setEmail: (email: string) => void
  authError: string
}
export const OTP_LENGTH = 6;

export const PremiumCalcalistPaywallDesktopSigninPassword = (props: PremiumCalcalistPaywallDesktopSigninPasswordProps) => {

  const { email, setPassword, onContinue, onBack, sendMail, authError } = props
  const [otp, setOtp] = useState<string[]>(Array(OTP_LENGTH).fill(''));
  const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(OTP_LENGTH).fill(null));
  
  const errorMesssagesServer: Partial<Record<PaywallAuthErrorCodes, string>> = {
    [PaywallAuthErrorCodes.AUTHENTICATION_FAILED]: 'האימות נכשל או שקוד האימות פג תוקף, אנא נסה שוב.'
  }

  const handleChange = (value: string, index: number) => {
    if (!/^\d*$/.test(value)) return; // Allow digits only
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    inputRefs.current[index].style.borderColor = '#999999';
    if (value && index < OTP_LENGTH - 1) {
      const nextInput = inputRefs.current[index + 1];
      if (nextInput) {
        nextInput.focus();
      }
    }
    if (newOtp.every((v) => v)) {
      setPassword(newOtp.join(''))
    }
  };

  const handleKeyDown = (event: KeyboardEvent, index: number) => {
    if (event.key === 'Backspace' && !otp[index] && index > 0) {
      const prevInput = inputRefs.current[index - 1];
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  const handlePaste = (event: ClipboardEvent) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text');
    if (pastedData.length !== otp.length || !/^\d+$/.test(pastedData)) {
      return;
    }
    const newOtp = pastedData.split('');
    setOtp(newOtp);
    if (inputRefs.current[otp.length - 1]) {
      inputRefs.current[otp.length - 1].focus();
    }
    setPassword(newOtp.join(''));
  };

  const handleSendMailAgain = () => {
    resetOtpInputs();
    sendMail();
  }

  const resetOtpInputs = () => {
    setOtp(Array(OTP_LENGTH).fill(''));
    inputRefs.current.forEach((input) => {
      if (input) {
        input.style.borderColor = '#999999'; 
      }
    });
  }

  const handleOnContinue = () => {
    const emptyOtpFields = inputRefs.current.filter((_, index) => otp[index] === '');
    if (emptyOtpFields.length > 0) {
      emptyOtpFields.forEach((input) => {
        if (input) {
          input.style.borderColor = '#DE1A1A';
        }
      });
    } else {
      onContinue();
    }
  }


  return (
    <div className="premium-calcalist-paywall-desktop-signin-password">
       <div className="digital-edition-label">המהדורה הדיגיטלית</div>
      <div className="title">איזה כיף שחזרת!</div>
      <div className="password-step-icon"></div>
      <div className="subtitle">
        <span>שלחנו קוד אימות לכתובת המייל שהזנת:</span><span className="bold">{email}</span>
        <div>יש להזין כאן את הקוד שקיבלת</div>
      </div>
      <div className="otp">
        {otp.map((_, index) => {
          return (
            <input
              key={index}
              ref={(el) => (inputRefs.current[index] = el)}
              type="text"
              maxLength={1}
              value={otp[index]}
              onChange={(e: any) => handleChange(e.target.value, index)}
              onKeyDown={(e: any) => handleKeyDown(e, index)}
              onPaste={(e: any) => handlePaste(e)}
            />
          );
        })}
      </div>
      <span className="avalibale-label">הקוד תקף ל-15 דקות</span>
      <div className="buttons-container">
        <button className="button-base back" onClick={onBack}>חזרה</button>
        <button className="button-base continue" onClick={handleOnContinue}>המשך</button>
      </div>
      {authError && <p className="error-text" dangerouslySetInnerHTML={{ __html: errorMesssagesServer[authError] }}></p>}
      <div className="resend-code-text">
        הקוד לא הגיע?<button onClick={handleSendMailAgain}>לשליחה מחדש</button>
      </div>
    </div>
  )
}
