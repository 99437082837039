import { HamburgerMenuItems } from "../../../calcalistWidgets/categoryHeader/components/hamburgerMenuList/menuList";
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { Logo } from "./siteMagazineHeaderComponenta";

interface MainNavProps {
    mainNavItems: HamburgerMenuItems[]
    onToggleMenu: () => void
    isOpened: boolean
    isSticky: boolean
    logoData: Logo
    onToggleSearch: () => void
}

export class MainNavHeaderComponenta extends React.Component<MainNavProps, {}> {

    private getSelectedClassName = (isSelected: boolean) => {
        if (isSelected) {
            return "selected"
        }
    }

    render() {
        const { mainNavItems, isOpened, onToggleMenu, isSticky, logoData, onToggleSearch } = this.props

        return (
            <div className="mainNav" role="navigation" aria-label="Main Menu">
                {isSticky &&
                    <HyperLinkBuilder
                        href={logoData.link}
                        linkTarget={logoData.linkTarget}
                        ariaLabel={logoData.mediaTitle}
                        className="magazineLogoInSticky"
                        style={{ "--logoImage": `url(${logoData.url})` }}
                    />
                }

                <div className="navList">
                    {_.map((mainNavItems), (item: HamburgerMenuItems, index: number) => <div key={index} >
                        <HyperLinkBuilder
                            href={item.link}
                            linkTarget={item.linkTarget}
                            style={{ color: item.color }}
                            className={this.getSelectedClassName(item.isSelected)}
                        >
                            <span dangerouslySetInnerHTML={{ __html: item.title }} />
                        </HyperLinkBuilder>
                    </div>)}
                </div>

                <div onClick={onToggleMenu} id="wcm_MenuDisplay">
                    <span className="moreBtn" id="wcm_MenuDisplay_moreBtn">עוד
                        <span className={isOpened ? "moreIcon toClose" : "moreIcon toOpen"} />
                    </span>
                </div>

                {isSticky &&
                    <div onClick={onToggleSearch} id="wcm_SearchDisplay">
                        <span className="searchIcon" id="wcm_MenuDisplay_searchIcon"></span >
                    </div>
                }
            </div>
        )
    }
}