
const CalcalistPaywallMyAccountLastPurchases = () => {

    const purchases = [
        { date: "14/7/23", type: "רכישת מינוי שנתי", action: "ביטול מינוי" },
        { date: "14/7/23", type: "ביטול מינוי חודשי" },
        { date: "13/7/24", type: "רכישת מינוי חודשי" },
    ];

    return (
        <div className="CalcalistPaywallMyAccountLastPurchases">
            <div className="table">
                <div className="header">
                    <span className="header-type">סוג חבילה</span>
                    <span className="header-date">תאריך</span>
                </div>
                <div className="list">
                    {purchases.map((purchase, index) => (
                        <div key={index} className="row">
                            <div className="type">{purchase.type} {index=== 0 && <button className="cancel">{purchase.action}</button>}</div>
                            <div className="date">{purchase.date}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CalcalistPaywallMyAccountLastPurchases;