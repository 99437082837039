require('./site_ynet_articleVerticalSocialShare.less');
import { SiteArticleSocialShareNew1280 } from "../articleSocialShare1280Component/articleSocialShareNew1280"
import * as ResizeObserver from "resize-observer-polyfill"


interface Props {
    categorySubChannel: string
    author: string
    articleId: string
    isBottomButtons?: boolean
    showComments: boolean
    isSpotim: boolean
    lang?: string
}

interface state {
    wrapperStyle: React.CSSProperties
}

export class SiteArticleVerticalSocialShare extends React.Component<Props, state>{
    static siteScriptName = "SiteVerticalArticleSocialShare"

    constructor(props) {
        super();
        //@ts-ignore
        if (ResizeObserver) {
            this.resizeObserver = new ResizeObserver(records => records.forEach(r => {
                this.onContentHeightChanged(r.contentRect.height)
            }))
        }


        this.state = {
            wrapperStyle: {
                height: "auto",
                visibility:props.showAlways ? "visible" : "hidden",
                opacity: 0,
                transition: "visibility 0s ease-out 100ms, opacity 400ms",
            },
        }
    }
    //@ts-ignore
    private resizeObserver: ResizeObserver;
    private intersectionObserver: IntersectionObserver;

    componentDidMount() {
        const isShowAlways = !document.getElementById("YnetHeader");
        if (IntersectionObserver) {
            this.intersectionObserver = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    this.setVisibility(entry.isIntersecting, isShowAlways)
                })
            })
        }
        const itemToMatchHeightWith =  document.getElementsByClassName("article-body")[0] || document.getElementsByClassName("article-body-radionas")[0];
        const widgetElement = document.getElementsByClassName("article-vertical-social-share")[0];
        const scrollTopOfItemToMatchHeightWith = itemToMatchHeightWith.getBoundingClientRect().top - widgetElement.getBoundingClientRect().top + window.pageYOffset;
        this.resizeObserver.observe(itemToMatchHeightWith);
        const  headerElem =document.getElementById("YnetHeader") || document.getElementById("RadionasHeader") || document.getElementById("MagazineHeader"); 
        this.intersectionObserver.observe(isShowAlways ? headerElem : document.getElementById("ArticleHeaderComponent"));
        this.setState({
            wrapperStyle: {
                ...this.state.wrapperStyle,
                top: isShowAlways ? "0px" : scrollTopOfItemToMatchHeightWith + "px",
            }
        })
    }

    componentWillUnmount() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
            this.resizeObserver = null;
        }
    }

    private onContentHeightChanged(height: number) {
const calcheight = document.getElementsByClassName("articleContainer magazine2025")[0] && 
((document.getElementsByClassName("article-tags-1280-2") ? 
document.getElementsByClassName("article-tags-1280-2")[0].getBoundingClientRect().height : 0) +  
(document.getElementsByClassName("article-body") ? 
document.getElementsByClassName("article-body")[0].getBoundingClientRect().height : 0) + 
(document.getElementsByClassName("Talkbacks-widget") ? 
document.getElementsByClassName("Talkbacks-widget")[0].getBoundingClientRect().height : 0));
let radionasHeight = document.getElementsByClassName("articleContainer radionas")[0] &&  document.getElementsByClassName("articleContainer radionas")[0].getBoundingClientRect().height;
const articleContainerHeight = calcheight ? calcheight : radionasHeight ? radionasHeight : height;
const isShowAlways = !document.getElementById("YnetHeader");       
this.setState({
            wrapperStyle: {
                ...this.state.wrapperStyle,
                height: isShowAlways ? `${articleContainerHeight}px` : `${height}px`
            },
        })
    }

    private setVisibility = (isIntersecting: boolean, isShowAlways:boolean) => {    
        this.setState({
            wrapperStyle: {
                ...this.state.wrapperStyle,
                visibility: isIntersecting &&  !isShowAlways ? "hidden" : "visible",
                opacity: isIntersecting && !isShowAlways ? 0 : 1,
            }
        })
    }

    render() {
        const { wrapperStyle } = this.state;
        return (
            <div className="SiteVerticalArticleSocialShare_Wrapper" id="SiteVerticalArticleSocialShare_Wrapper" style={wrapperStyle}>
                <SiteArticleSocialShareNew1280 {...this.props} verticalSidebar/>
            </div>
        )
    }
}
