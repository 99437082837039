
const PremiumPaywallMobileSignupFinish = () => {
  return (
    <div className="premium-calcalist-paywall-mobile-signup-finish">
        <h1 className="title">סיימנו!</h1>
        <p className="subtitle">מיד תועברו למהדורה</p>
        <div className="finish-step-icon"></div>
        <div className="change-page-message">
            העמוד לא התחלף?  <span onClick={()=>window.location.reload()}>לחצו כאן</span>
        </div>
    </div>
  )
}

export default PremiumPaywallMobileSignupFinish