require("./radionas_site_redmailComponenta.less")

import { ExtraDetails } from "./extraDetails/extraDetails"
import { TextArea } from "./textArea/textArea"
import { PopUp } from "./popUp/popUp"
import { RedMailFileUploadYnet } from "./filesUpload/RedMailFileUploadYnet";
import { RedMailComponentaProps } from "../../../redMail/interfaces/redMailInterfaces";


export interface RedMailComponentaRadionasProps extends RedMailComponentaProps {

}

export class RedMailComponentaRadionas extends React.Component<RedMailComponentaRadionasProps & {isChecked?: boolean}, {}>{

    public render() {
        const { handleChangeText, isTextAreaBlank, countSubmitAttempts, textArea,
                handleSelectedFile, selectedFiles, hashes, invalidFilesName, showFileStatusProgress,
                photographer, sender, mail, phoneNumber, validation, handleChangeExtraData, isAgreementApproved,
                IsPopUp, submitForm, loading, onClose, submitButtonDisabled, isChecked } = this.props;

        const ynetTermsHref = "https://z.ynet.co.il/short/content/2018/privacypolicy/terms.html";
        const ynetPolicyHref = "https://z.ynet.co.il/short/content/2018/privacypolicy/policy.html";
        const ynetTermsLink = <a href={ynetTermsHref} className="termsClick">תנאי השימוש</a>
        const ynetPolicyLink = <a href={ynetPolicyHref} className="termsClick">מדיניות הפרטיות</a>

        const textData = {
            headlineText: "أرسلوا لنا",
            textArea: {
                textAreaHeadline: 'ترغبون بإرسال خبر أو دعوة للتغطية أو معلومات لنشرها في منصات وتطبيق "ناس"؟',
                textAreaHeadlineShareButtonsActive: 'هل لديك معلومات هامّة لم يتم الحديث عنها بعد؟ هل ترغب بمشاركة أخبار أو صور لحدثٍ ما؟',
                textAreaHeadlineShareButtonsActiveSecondLine:"يسرّنا التعاون معكم",
                placeholder: "يمكنكم مشاركتنا هنا",
            },
            extraDetailsData: {
                title: " تفاصيل إضافية (اختياري)",
                subTitle: "",
                mailPattern: "يجب الكتابة وفق الصيغة التالية: email@domain.com "
            },
            terms: <>اؤكد أنَّ المحتوى المرسل ليس كاذبًا أو مزيّفًا، ولم يتم انتاجه خصيصًا، كما أنّه ليس منقولاً أو منسوخًا بطريقة غير قانونيّة ولا يندرج ضمن التعديّ على الممتلكات أو ينتهك الخصوصيّة.  لم يتم ارسال المحتوى إلى جهة أخرى بنفس الوقت. البيانات المرفقة موثوقة وصالحة. قرأت ووافقت على سياسة الخصوصيّة وشروط الاستخدام الخاصّة بتطبيق "ناس"</>
        }

        return (
            <div>
                <div className="RedMailComponenta">
                    <div className="redMailLeftSide">
                        <div className="redMailHeadline">
                            <p className="headlineText">{textData.headlineText}</p>
                        </div>
                        
                        <TextArea borderColor={(isTextAreaBlank && countSubmitAttempts > 0) ? "#B90302" : "#9BA9C1"  }textAreaData={textData.textArea} value={textArea} isChecked={isChecked} onChange={handleChangeText} isTextAreaBlank={isTextAreaBlank && countSubmitAttempts > 0} />
                        {(isTextAreaBlank && countSubmitAttempts > 0) && <div className="contentErrorMessage">نسيتم كتابة فحوى رسالة</div>}
                        <RedMailFileUploadYnet
                            handleSelectedFile={handleSelectedFile}
                            selectedFiles={selectedFiles}
                            hashes={hashes}
                            invalidFilesName={invalidFilesName}
                            showFileStatusProgress={showFileStatusProgress}
                        />

                        <ExtraDetails
                            photographer={photographer}
                            sender={sender}
                            mail={mail}
                            phoneNumber={phoneNumber}
                            onChange={handleChangeExtraData}
                            validation={validation}
                            extraDetailsData={textData.extraDetailsData}
                        />

                        <p className="terms">
                            <input className="checkBox" id="agreementApproved" type="checkbox" checked={isAgreementApproved} onChange={(e) => handleChangeExtraData(e)} />
                            {textData.terms}
                        </p>

                        {(!isAgreementApproved && countSubmitAttempts > 0) && <div className="contentErrorMessage terms-error">يجب الموافقة على شروط الاستخدام</div>}
                        <button
                            disabled={submitButtonDisabled}
                            className="submitButton"
                            type="submit"
                            onClick={submitForm}>
                            إرسال
                        </button>
                        {IsPopUp && <PopUp onClose={onClose} loading={loading} />}
                    </div>
                </div>
            </div>
        )

    }
}



