import { useState } from "react";
import CalcalistPaywallCheckoutPlans, { SubscriptionPlan } from "./calcalistPaywallCheckoutPlans";
import CalcalistPaywallCheckoutPersonalDetails from "./calcalistPaywallCheckoutPersonalDetails";
import CalcalistPaywallCheckoutMultiStepsProgressbar from "./multiStepProgressbar/calcalistPaywallCheckoutMultiStepsProgressbar";
import CalcalistPaywallCheckoutPaymentDetails from "./calcalistPaywallCheckoutPaymentDetails";
import CalcalistPaywallCheckoutFinish from "./calcalistPaywallCheckoutPaymentFinish"
export const CalcalistPaywallCheckoutComponenta = () => {

    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
    const [selectedPlan, setPlan] = useState<SubscriptionPlan>(null);

    enum CheckoutStep {
        SELECT_PLAN = "בחירת מסלול",
        PERSONAL_DETAILS = "פרטים אישיים",
        PAYMENT_DETAILS = "פרטי תשלום",
        FINISH = "סיום",
    }

    const steps = Object.values(CheckoutStep);


    const nextStep = () => {
        setCurrentStepIndex(Math.min(currentStepIndex + 1, steps.length - 1));
    };

    const prevStep = () => {
        setCurrentStepIndex(Math.max(currentStepIndex - 1, 0));
    };

    const renderStepContent = () => {
        switch (steps[currentStepIndex]) {
            case CheckoutStep.SELECT_PLAN:
                return <CalcalistPaywallCheckoutPlans nextStep={nextStep} setPlan={setPlan} />;
            case CheckoutStep.PERSONAL_DETAILS:
                return <CalcalistPaywallCheckoutPersonalDetails nextStep={nextStep} prevStep={prevStep} />;
            case CheckoutStep.PAYMENT_DETAILS:
                return <CalcalistPaywallCheckoutPaymentDetails nextStep={nextStep} prevStep={prevStep} selectedPlan={selectedPlan} />;
            case CheckoutStep.FINISH:
                return <CalcalistPaywallCheckoutFinish nextStep={nextStep} />;
            default:
                return null;
        }
    };



    return (
        <div className="CalcalistPaywallCheckoutComponenta">
            <div className={`gradient-background ${Object.keys(CheckoutStep)[currentStepIndex] || ''}`}></div>
            <div className="calcalist-logo-container">
                <div className="calcalist-logo"></div>
                <div className="calcalist-digital-edition-text">המהדורה הדיגיטלית</div>
            </div>
            <CalcalistPaywallCheckoutMultiStepsProgressbar steps={steps} currentStepIndex={currentStepIndex} />
            {renderStepContent()}
        </div>
    )
}

CalcalistPaywallCheckoutComponenta.siteScriptName = "CalcalistPaywallCheckoutComponenta"
