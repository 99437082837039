import { useState } from 'react';
import { PremiumPaywallSignModes } from '../../../../../global/paywall/paywallInterfaces';
import { PremiumCalcalistPaywallDesktopSignup } from './premiumCalcalistPaywallDesktopSignUp';
import { PremiumCalcalistPaywallDesktopSignin } from './premiumCalcalistPaywallDesktopSignin';
 
 


export const PremiumCalcalistPaywallDesktop = () => {
  const [mode, setMode] = useState<PremiumPaywallSignModes>('signIn');
  const [email, setEmail] = useState<string>('');
  const [agreesTerms, setAgreeTerms] = useState(false);
  const [password, setPassword] = useState('');

  const getFormByMode = () => {
    if (mode === 'signUp') {
      return (
        <PremiumCalcalistPaywallDesktopSignup
          email={email}
          password={password}
          agreesTerms={agreesTerms}
          setEmail={setEmail}
          setPassword={setPassword}
          setAgreesTerms={setAgreeTerms}
          setMode={setMode}
        />
      );
    }
    return (
      <PremiumCalcalistPaywallDesktopSignin
        email={email}
        password={password}
        agreesTerms={agreesTerms}
        setEmail={setEmail}
        setPassword={setPassword}
        setAgreesTerms={setAgreeTerms}
        setMode={setMode}
      />);
  };

  return <div className="premium-calcalist-paywall-desktop">{getFormByMode()}</div>;
};
