export enum PaywallAuthErrorCodes {
  INVALID_EMAIL = "INVALID_EMAIL",
  INVALID_PASSWORD = "INVALID_PASSWORD",
  EMAIL_ALREADY_EXISTS = "EMAIL_ALREADY_EXISTS",
  NOT_REGISTERED = "NOT_REGISTERED",
  SIGNUP_NO_AGREEMENT = "SIGNUP_NO_AGREEMENT",
  ALREADY_SIGNED = "ALREADY_SIGNED",
  TOKEN_EXPIRED = "TOKEN_EXPIRED",
  GOOGLE_SIGN_IN_FAILED = "GOOGLE_SIGN_IN_FAILED",
  APPLE_SIGN_IN_FAILED = "APPLE_SIGN_IN_FAILED",
  THIRD_PARTY_LOGIN_FAILED = "THIRD_PARTY_LOGIN_FAILED",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  FAILED_TO_SEND_OTP = "FAILED_TO_SEND_OTP",
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  MISSING_EXPIRATION_TIME_IN_ID_TOKEN = "MISSING_EXPIRATION_TIME_IN_ID_TOKEN"
}

export enum AuthStepsEnum {
  EMAIL = "email",
  PASSWORD = "password",
  CONFIRM_TERMS = "confirmTerms",
  FINISH = "finish"
}


export enum PaywallPurchaseErrorCodes {
  INVALID_RESPONSE = "Invalid response from server",
  UNKNOWN_ERROR = "An unknown error occurred",
  PAYMENT_FAILED = "Payment failed. Please try again",
  UNEXPECTED_STATUS = "Unexpected payment status"
}

export type PremiumPaywallSignModes = "signUp" | "signIn"
export type LoginService = "google" | "apple"

export interface GoogleSignInResponse {
  access_token: string; // The OAuth 2.0 access token for the user
  expires_in: number;   // The lifetime in seconds of the access token
  token_type: string;   // The type of token returned (usually "Bearer")
  scope: string;        // The scopes granted to the token
  id_token?: string;    // The ID token for the user (if requested in scopes)
  refresh_token?: string; // The refresh token (if offline access is requested)
}

export interface AppleSignInResponse {
  authorization: {
    code: string;         // The authorization code used to exchange for tokens
    id_token: string;     // A JWT containing user identity information
    state?: string;       // The state parameter passed in the initial request (optional)
  };
  user?: {
    name?: {
      firstName: string;  // The user's first name (if requested and available)
      lastName: string;   // The user's last name (if requested and available)
    };
    email?: string;       // The user's email address (if requested and available)
  };
}

export interface ThirdPartyLoginResponse extends Partial<CustomerAccessData> {
  token: string
  isNewCustomer: boolean
}

export interface CustomerAccessData {
  id: number
  claims: string[]
  displayName: string
}