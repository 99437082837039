
export function getCurrentUser() {
    const user = localStorage.getItem('paywall');
    if(user) {
        return JSON.parse(user);
    }
}

export function removeCurrentUser() {
    localStorage.removeItem('paywall');
}