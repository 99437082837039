import { useState,useEffect } from 'react';
import { PremiumPaywallSignModes } from '../../../../../global/paywall/paywallInterfaces';
import { PremiumCalcalistPaywallMobileSignUp as PremiumCalcalistPaywallMobileSignUp } from "./premiumCalcalistPaywallMobileSignup"
import { PremiumCalcalistPaywallMobileLogin as PremiumCalcalistPaywallMobileLogin } from './premiumCalcalistPaywallMobileLogin';
 


export const PremiumCalcalistPaywallMobile = ({isArticle}:{isArticle?:boolean}) => {
  const [mode, setMode] = useState<PremiumPaywallSignModes>('signUp');
  const [email, setEmail] = useState<string>('');
  const [agreesTerms, setAgreeTerms] = useState(false);
  const [password, setPassword] = useState('');


  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);


  const getFormByMode = () => {
    if (mode === 'signUp') {
      return (
        <PremiumCalcalistPaywallMobileSignUp
          email={email}
          password={password}
          agreesTerms={agreesTerms}
          setEmail={setEmail}
          setPassword={setPassword}
          setAgreesTerms={setAgreeTerms}
          setMode={setMode}
        />
      );
    }
    return (
      <PremiumCalcalistPaywallMobileLogin
        email={email}
        password={password}
        agreesTerms={agreesTerms}
        setEmail={setEmail}
        setPassword={setPassword}
        setAgreesTerms={setAgreeTerms}
        setMode={setMode}
      />);
  };

  return <div className="premium-calcalist-paywall-mobile">{getFormByMode()}</div>;
};
