
const PremiumCalcalistPaywallDesktopSigninFinish = () => {
  return (
    <div className="premium-calcalist-paywall-desktop-signin-finish">
        <div className="digital-edition-label">המהדורה הדיגיטלית</div>
        <h1 className="title">סיימנו!</h1>
        <p className="subtitle">מיד תועברו למהדורה הדיגיטלית</p>
        <div className="finish-step-icon"></div>
        <div className="change-page-message">
            העמוד לא התחלף? לחצו <span onClick={()=>window.location.reload()}>כאן</span>
        </div>
    </div>
  )
}

export default PremiumCalcalistPaywallDesktopSigninFinish