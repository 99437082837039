import { PodcastPlayerControls } from "./podcastPlayerControls"
import { AudioPlayButton, MediaMetadata } from "../../../../radioWidgets/floatingPlayer/audioPlayButton"
import { ArticleData } from "articlePage/stateInterfaces"
import { ArticleComponentaContext } from "../../../../../interfaces";

interface PodcastPlayerControlsComponentProps extends Partial<ArticleComponentaContext>{
    audioUrl: string
    durationSeconds: number  
    articleData:Partial<ArticleData>  
}

export class PodcastPlayerControlsComponent extends React.Component<PodcastPlayerControlsComponentProps> {
    public static siteScriptName = 'PodcastPlayerControlsComponent'
    render() {

        const { audioUrl, durationSeconds, articleData } = this.props;
        const browserDisplayMediaMetaData = { title:  articleData.title, artist: "calcalist", album: articleData.category.textToShow}
        return (
            <AudioPlayButton
                audioUrl={audioUrl}
                durationSeconds={durationSeconds}
                renderer={() => null}
                articleData={articleData}
                browserDisplayMediaMetaData={browserDisplayMediaMetaData}
                floatingPlayerRenderer={(props) => (<PodcastPlayerControls {...props} />)}            
            />
        )
    }
}