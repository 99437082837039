import { SiteMedia } from "../../../../siteWidgets/siteMedia";
import { VideoSettingsData } from "widgets/commonDataObjects/interfaces";
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink";
import { videoList } from "../../sideVideoGallery/components/frontSideVideoComponenta";
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay";
import { separateHourFromDateAndFormatToString } from "../../../../widgetsUtils";

interface SideVideoProps {
	videoItems: videoList[];
	videoSettings: VideoSettingsData;
	numOfItems: number;
	titleColor: string;
	isAuthorVisible: boolean;
	isSubTitleVisible: boolean;
	isDateVisible: boolean;
	isHourVisible: boolean;
}

interface SideVideoState {
	selectedId: string;
	videoPlayStarted: boolean;
	page: number;
}

export class FrontTvCalcalist extends React.Component<SideVideoProps, SideVideoState> {
	public static siteScriptName = "FrontTvCalcalist";
	constructor(props) {
		super(props);
		this.state = {
			selectedId: props.videoItems[0].id,
			videoPlayStarted: false,
			page: 0,
		};
	}
	private selectItem = (id: string) => {
		this.setState({ selectedId: id });
	};

	private handleOnVideoPlayStarted = () => {
		this.setState({ videoPlayStarted: true });
	};

	private handleOnVideoPlayStopped = () => {
		this.setState({ videoPlayStarted: false });
	};

	private goPrev = () => {
		this.setState({ page: 0 });
	};

	private goNext = () => {
		this.setState({ page: 1 });
	};

	render() {
		const { videoItems, videoSettings, numOfItems, titleColor, isAuthorVisible, isSubTitleVisible, isDateVisible, isHourVisible, } = this.props;
		const { selectedId, page } = this.state;
		const notSelectedItems = videoItems.filter((i) => i.id !== selectedId);
		const selectedItem = videoItems.find((i) => i.id === selectedId);
		const bottomListStyle = { width: 296 * notSelectedItems.length + "px", marginRight: page === 0 ? 0 : -888 };
		const buttonClassName = page === 1 ? "arrowPrev" : "arrowNext";

		return (
			<div className="CalcalistTvContent">
				<div className="mainVideo">
					<div className="slotView">
						<HyperLinkBuilder
							className="textArea"
							href={selectedItem.link}
							linkTarget={selectedItem.linkTarget}
							isDataTbLink
						>
							<div
								className="itemTitle"
								style={{ color: titleColor }}
								dangerouslySetInnerHTML={{ __html: selectedItem.title }}
								data-tb-title=""
							/>
							{isSubTitleVisible && (
								<div className="itemSubTitle" dangerouslySetInnerHTML={{ __html: selectedItem.subTitle }} data-tb-description=""/>
							)}
							<div className="authorAndDateWrapper">
								{isAuthorVisible && selectedItem.author.length > 0 && (<div className="authorInfo" data-tb-author>{selectedItem.author}</div>)}
								{(isDateVisible || isHourVisible) && (
									<div className="itemDate" data-tb-date="">
										{separateHourFromDateAndFormatToString(selectedItem.date, {
											showHour: isHourVisible,
											showDate: isDateVisible,
										})}
									</div>
								)}
							</div>

						</HyperLinkBuilder>
						<div className="videoPlayerPlaceholder">
							{selectedItem.video !== null ? (
								<SiteMedia
									key={selectedItem.video.mediaId}
									onPlayStarted={() => this.handleOnVideoPlayStarted()}
									onPlayStopped={() => this.handleOnVideoPlayStopped()}
									data={selectedItem.video}
									videoSettings={videoSettings}
									width={580}
									height={Math.floor((580 / 16) * 9)}
								/>
							) : (<div />)}
						</div>
					</div>
				</div>
				{numOfItems > 1 && (
					<div className="bottomList">
						<div className="bottomItems" style={bottomListStyle}>
							{notSelectedItems.map((item, i) => (
								<div
									className="videoItem slotView"
									key={i}
									onClick={() => this.selectItem(item.id)}
								>
									<div className="lisPreviewImage">
										<img src={item.video.poster} data-tb-thumbnail/>
										<SlotIconDisplay itemId={""} icon={"video"} iconClassName="medium" isIconVisible={true} />
									</div>
									<div className="itemTitle" data-tb-title="">
										<div style={{ color: titleColor }} dangerouslySetInnerHTML={{ __html: item.title }} />
									</div>
									<div className="authorAndDateWrapper">
										{isAuthorVisible && item.author.length > 0 && (
											<div className="authorInfo" data-tb-author>{item.author}</div>
										)}
										{(isDateVisible || isHourVisible) && (
											<div className="itemDate" data-tb-date="">
												{separateHourFromDateAndFormatToString(item.date, { showHour: isHourVisible, showDate: isDateVisible })}
											</div>
										)}
									</div>

								</div>
							))}
						</div>
					</div>
				)}
				{numOfItems === 7 && 
					<button className={buttonClassName} onClick={page === 1 ? this.goPrev : this.goNext} />
				}
			</div>
		);
	}
}
