
interface CalcalistPaywallCheckoutPlansProps {
    nextStep: () => void
    setPlan: (selectedPlan: SubscriptionPlan) => void
}

enum ProductsUniqueIds {
    MONTHLY_PASS = 'monthlyPass',
    YEARLY_PASS = 'yearlyPass',
};

export interface SubscriptionPlan {
    title: string;
    price: number;
    frequency: string;
    buttonText: string;
    cancelText: string;
    className: string;
    productUniqueId: ProductsUniqueIds
}


export const plansObj = {
    "monthly": {
        title: "חודשי",
        price: 29.90,
        frequency: "בתשלום חודשי מתחדש",
        buttonText: "להצטרפות",
        className: "monthly-plan",
        productUniqueId: 'monthlyPass'

    },
    "yearly": {
        title: "שנתי",
        price: 299.90,
        frequency: "בתשלום שנתי מתחדש",
        buttonText: "להצטרפות",
        className: "yearly-plan",
        productUniqueId: 'yearlyPass'
    }
}

const CalcalistPaywallCheckoutMobilePlans = (props: CalcalistPaywallCheckoutPlansProps) => {
    const { nextStep, setPlan } = props;


    const handleNextStep = (selectedPlan) => {
        setPlan(selectedPlan)
        nextStep()
    }




    return (
        <div className="CalcalistPaywallCheckoutPlans">
            <div className="plans-title">
                רוצים להמשיך לקרוא ללא הגבלה <br />
                את המהדורה הדיגיטלית? <br />
                לרכישת מינוי של עיתון כלכליסט בדיגיטל
            </div>
            <div className="plans-container">
                {
                    Object.keys(plansObj).map((key) => {
                        const plan = plansObj[key]
                        return (
                            <div key={key} className={`plan-box ${plan.className}`}>
                                <div className="plan-title">מסלול חיוב {plan.title} </div>
                                <div className="inner-container">
                                    <div className="plan-price">
                                        <span>{plan.price.toFixed(2)}</span>
                                        <div className="plan-price-frequency">{plan.frequency}</div>
                                    </div>
                                    <button className="plan-button" onClick={() => handleNextStep(plan)}>{plan.buttonText}</button>
                                </div>
                                <div className="plan-cancel-text">
                                    <span>ניתן לביטול בכל עת</span>|
                                    <a href="https://www.calcalist.co.il/home/0,7340,L-3856,00.html">לתנאי השימוש</a>
                                    <a href="https://z.calcalist.co.il/mvc/long/2018/OrganizationalStructure/About/Privacy.html">ומדיניות פרטיות</a>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    );
};

export default CalcalistPaywallCheckoutMobilePlans;