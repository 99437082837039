import * as filter from "lodash/filter"

export const ynetLiveTranslations =
{
    "he": {
        "Now Live": "עכשיו בשידור",
        "live broadcast": "שידור חי",
        "Watch": "לצפייה",
        "Listen": "להאזנה",
        "Listen to the broadcast":"האזינו לשידור",
        "No broadcasts":"אין תוכנית לנגן",
        "Ad":"מודעה",
    },
    "en": {
        "Now Live": "Now Live",
        "live broadcast": "live broadcast",
        "Watch": "Watch",
        "Listen": "Listen",
        "Listen to the broadcast":"Listen to the broadcast",
        "No broadcasts":"No broadcasts",
        "Ad":"Ad",
    },
    "ru": {
        "Now Live": "Сейчас в эфире",
        "live broadcast": "Прямой эфир",
        "Watch": "Смотреть",
        "Listen":"Слушать",
        "Listen to the broadcast":"Послушать трансляцию",
        "No broadcasts":"трансляции отсутствуют",
        "Ad":"объявление",
    }
}


export const getTranslation = (lang, text: string) => {
    const obj = filter(ynetLiveTranslations, function (v, k) {
        return k === lang;
    });
    const content = filter(obj[0], function (v, k) {
        return k === text;
    })
    return content[0]
}