import CalcalistPaywallMyAccountPersonalDetails from "./calcalistPaywallMyAccountPersonalDetails"
import CalcalistPaywallMyAccountLastPurchases from "./calcalistPaywallMyAccountpLastPurchases"
import CalcalistPaywallMyAccountTabs from "./calcalistPaywallMyAccountDesktopTabs"
 
const CalcalistPaywallMyAccountComponenta = () => {

    const tabs = [
        {
            label: "המשתמש שלי",
            content: <CalcalistPaywallMyAccountPersonalDetails />,
        },
        {
            label: "רכישות אחרונות",
            content: <CalcalistPaywallMyAccountLastPurchases />,
        },
    ];

    return (
        <div className="CalcalistPaywallMyAccountComponenta">
            <CalcalistPaywallMyAccountTabs tabs={tabs} />
        </div>
    );
};

export default CalcalistPaywallMyAccountComponenta;

CalcalistPaywallMyAccountComponenta.siteScriptName = "CalcalistPaywallMyAccountComponenta"

