import * as map from "lodash/map"
import { LinkedItemFieldDisplay } from "../../../../commonDataObjects/linkedItemsFieldsDisplay"
import { LinkedItemVideoSettings } from "../../../../commonDataObjects/linkedItemsVideoSettings"
import { LinkTargetProperties } from "../../../../../global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink"
import { CalcalistSpecialContentItemFront } from "./calcalistSpecialContentItemFront"
import { MultiImagesScroller } from "../../../../commonComponents/base/multiImagesFrontScroller"
require("./ctech_calcalist_site_calcalistSpecialContentFront.less")

interface CalcalistSpecialContentFrontProps {
    tabText: string
    tabLink: string
    tabLinkTarget: LinkTargetProperties
    titleTextColor: string
    stirpsColor: string
    backgroundColor: string
    isStripsVisible: boolean
    isTabVisible: boolean
    imageWidth: number
    imageHeight: number
    itemsContainerWidth: number
    height: number
    itemList: any //TODO: make type for items
    fieldsDisplaySettings: LinkedItemFieldDisplay
    videoSettings: LinkedItemVideoSettings
    naturalWidth: number
    className: string
    isBigImage: boolean
    isLTR?: boolean
    numberOfVisibleItems: number
    isMobileSettings?: boolean
}

export class CalcalistSpecialContentFront extends React.Component<CalcalistSpecialContentFrontProps>{
    public static siteScriptName = "CalcalistSpecialContentFront"

    private getItemMargin = (componentWidth: number, imageWidth: number) => {
        let numberofVisibleItems = Math.floor(componentWidth / imageWidth)
        let imagesWidth = numberofVisibleItems * imageWidth
        let spaceForMargin = componentWidth - imagesWidth
        let marginLeft = spaceForMargin / (numberofVisibleItems - 1)
        return marginLeft
    }

    render() {
        const { isStripsVisible, naturalWidth, videoSettings, imageWidth, imageHeight,
            titleTextColor, stirpsColor, backgroundColor, height, itemsContainerWidth: width, tabLink,
            tabLinkTarget, tabText, itemList, isTabVisible, className, isBigImage, isLTR, numberOfVisibleItems, isMobileSettings } = this.props
        const containerStyles = {
            width: `${naturalWidth}px`,
            height: `${height}px`,
            backgroundColor: backgroundColor,
            borderTop: `3px solid ${isStripsVisible ? stirpsColor : backgroundColor}`,
            borderBottom: `3px solid ${isStripsVisible ? stirpsColor : backgroundColor}`,
        }
        const items = map(itemList, (item, i) => {
            return <CalcalistSpecialContentItemFront
                key={i}
                itemData={item}
                videoSettings={videoSettings}
                mediaWidth={imageWidth}
                mediaHeight={imageHeight}
                titleTextColor={titleTextColor}
                isLTR={isLTR}
                marginLeft={isLTR ? (i === 0 ? 0 : this.getItemMargin(width, imageWidth)) : (i === numberOfVisibleItems - 1 ? 0 : this.getItemMargin(width, imageWidth))}
            />
        })
        const bigImageClass = isBigImage ? "bigImage" : ""
        const slotsWrapperStyles = {
            paddingTop: isTabVisible ? '0px' : '20px'
        }
        const ctechClassName = isLTR ? 'Ctech' : ''
        return (
            <div className={`CalcalistSpecialContentFront ${className} ${bigImageClass}${ctechClassName}`} style={containerStyles}>
                {isTabVisible &&
                    <HyperLinkBuilder
                        href={tabLink}
                        linkTarget={tabLinkTarget !== undefined && tabLinkTarget} >
                        <span className="tabTitle" style={{ color: stirpsColor }} data-tb-title=""> {tabText}</span>
                    </HyperLinkBuilder>}
                <div className="slotsWrapper" style={slotsWrapperStyles}>
                    <MultiImagesScroller width={width} imageWidth={imageWidth} itemLength={numberOfVisibleItems} isLTR={isLTR}>
                    {items}
                    </MultiImagesScroller>
                </div>
            </div>
        )
    }
}