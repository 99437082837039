require("./ctech_calcalist_site_calcalistComponentaShareMenu.less");
require("./ctech_site_calcalistComponentaShareMenu-mobile.less");
import { openFacebookShare, openTwitterShare, openMailShare, analyticsPushFunc, openLinkedinShare, openWhatsAppShare, } from "../../widgetsUtils";
import { isWcmEditor } from "config/serverConfig";

interface CalcalistComponentaShareMenuState {
    isButtonsVisible: boolean;
}
interface shareMenuProps {
    id?: string;
    url: string;
    title?: string;
    isLTR?: boolean;
    isArticle?: boolean;
    showOnHover?: boolean;
    showWA?: boolean;
    isResponsive?: boolean;
}

export class CalcalistShareMenu extends React.Component<shareMenuProps, CalcalistComponentaShareMenuState> {

    public static siteScriptName = "CalcalistShareMenu";

    constructor(props: shareMenuProps) {
        super(props);
        this.state = {
            isButtonsVisible: false,
        };
    }

    private openShare = async () => {
        const { title, id, url } = this.props;
        const shareData = { url: url + "#" + id, title: title };

        try {
            // @ts-ignore
            await navigator.share(shareData);
        } catch (err) {
            console.log("error with sharing function", err);
        }
    };

    private toggleMenu = (value?: boolean) => {
        const { isButtonsVisible } = this.state;
        this.setState({ isButtonsVisible: value === undefined ? !isButtonsVisible : value });
    };

    render() {
        const { isButtonsVisible } = this.state;
        const { url, title, isLTR, isArticle, showOnHover, showWA, id, isResponsive } = this.props;
        const isVisible = isButtonsVisible ? "fadeIn" : "";
        const isApp = typeof window !== "undefined" && window.location.href.indexOf("IsApp") != -1;

        return (
            <div className="ShareMenu">
                <div
                    className={`shareBtnsBox ${isVisible}`}
                    onMouseEnter={showOnHover && !isResponsive ? () => this.toggleMenu(true) : null}
                    onMouseLeave={showOnHover && !isResponsive ? () => this.toggleMenu(false) : null}
                >
                    <button
                        className="calcalistShare fb"
                        onClick={() =>
                            !isWcmEditor() ?
                                openFacebookShare(url, title, () => analyticsPushFunc("Facebook", "Article Main Image", title), "Article Main Image")
                                :
                                null
                        }
                    />

                    <button
                        className="calcalistShare tw"
                        onClick={() =>
                            !isWcmEditor() ?
                                openTwitterShare(url, () => analyticsPushFunc("Twitter", "Article Main Image", title), "Article Main Image")
                                :
                                null
                        }
                    />

                    {isArticle &&
                        <button
                            className="calcalistShare mail"
                            onClick={() =>
                                !isWcmEditor() ?
                                    openMailShare(title, "כלכליסט", url, () => analyticsPushFunc("Mail", "Article Main Image", title), "Article Main Image")
                                    :
                                    null
                            }
                        />
                    }

                    {isLTR &&
                        <button
                            className="calcalistShare li"
                            onClick={() =>
                                !isWcmEditor() ?
                                    openLinkedinShare(url, () => analyticsPushFunc("Linkedin", "Article Main Image", title))
                                    :
                                    null
                            }
                        />
                    }

                    {(isLTR || showWA) &&
                        <button
                            className="calcalistShare wa"
                            onClick={() =>
                                !isWcmEditor() ?
                                    openWhatsAppShare(url, () => analyticsPushFunc("Mail", "Article Main Image", title), "Article Main Image")
                                    :
                                    null
                            }
                        />
                    }
                </div>

                {isApp && isResponsive ?
                    <a href={`share_article/${id}`} title={"share"} className="toggleShareButtons"></a>
                    :
                    <button
                        onClick={!isResponsive ? () => this.toggleMenu() : null}
                        onMouseEnter={showOnHover && !isResponsive ? () => this.toggleMenu(true) : null}
                        onMouseLeave={showOnHover && !isResponsive ? () => this.toggleMenu(false) : null}
                        className={`toggleShareButtons ${isVisible} ${isResponsive ? "mobileShareBtn" : ""}`}
                    />
                }
            </div>
        );
    }
}
