/* eslint-disable @typescript-eslint/no-explicit-any */
import { useGoogleLogin } from '@react-oauth/google';
import * as axios from "axios"
import { useState } from 'react';
import { generateRecaptchaToken, PAYWALL_LOCAL_STORAGE_KEY } from './authOperations';
import { postPaywallAuthAppleSignIn, postPaywallAuthGoogleSignIn, postPaywallAuthThirdPartyEndSignIn, postPaywallSignedIn, postPaywallSignedInEnd, postPaywallSignedUp, postPaywallSignedUpEnd } from './paywallApiUrls';
import { AppleSignInResponse, AuthStepsEnum, CustomerAccessData, GoogleSignInResponse, ThirdPartyLoginResponse, PaywallAuthErrorCodes } from './paywallInterfaces';
/*
  Wrap your app with 'GoogleOAuthProvider' from '@react-oauth/google' and pass the Google client_id to it.
  Then, call loginWithGoogle() and loginWithApple() from the component when appropriate.
*/

/* use Apple’s JavaScript SDK (https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js) */

const APPLE_CLIENT_ID = 'com.ynetQA-connect.ynet';
const APPLE_REDIRECT_URI = 'https://daniel.ynet.co.il:5173/';


interface UseAuthReturn {
  authError: string | null;
  step: AuthStepsEnum;
  loginWithGoogle: () => void;
  loginWithApple: () => void;
  thirdPartyLogin: (isAgree: boolean) => void;
  sendEmailForLogin: (email: string, agreesTerms?: boolean) => Promise<boolean>;
  submitPassword: (password: string) => Promise<boolean>;
  resetAuth: () => void;
}

const validateEmail = (email: string) => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return typeof email === "string" && emailRegex.test(email)
}

export const getAuthHookFactory = (type: "signup" | "login") => (): UseAuthReturn => {
  const [authError, setAuthError] = useState<string | null>(null);
  const [step, setStep] = useState<AuthStepsEnum>(AuthStepsEnum.EMAIL);
  const [email, setEmail] = useState<string | null>(null);
  const [tokenExpireTimeStamp, setTokenExpireTimeStamp] = useState<Date | null>(null)
  const [tokenResponse, setTokenResponse] = useState<string | null>(null)


  const decodeJWT = (token: string): any => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    return JSON.parse(jsonPayload);
  };

  const createExpireTimeStamp = (expireIn: number) => {
    return new Date(Date.now() + expireIn * 1000)
  }

  const handleExistingCustomer = (data: ThirdPartyLoginResponse) => {
    const customerAccessData: CustomerAccessData = { id: data.id || -1, claims: data.claims || [], displayName: data.displayName || "" };
    handleLoginSuccess(customerAccessData)
  }

  const handleLoginSuccess = (resData: any) => {
    localStorage.setItem(PAYWALL_LOCAL_STORAGE_KEY, JSON.stringify(resData));
    setStep(AuthStepsEnum.FINISH);
    setAuthError(null);
    setTokenExpireTimeStamp(null)
    setTokenResponse(null)
    setEmail(null)
  }

  const googleLogin = useGoogleLogin({
    onSuccess: async (response: GoogleSignInResponse) => {
      try {
        const res = await axios.post<{ data: ThirdPartyLoginResponse }>(postPaywallAuthGoogleSignIn(), { thirdPartyToken: response.access_token });
        const resPayload = res.data.data

        if (!resPayload.isNewCustomer) {
          handleExistingCustomer(resPayload)
          return
        }

        const token = resPayload.token;
        setTokenResponse(token);
        setTokenExpireTimeStamp(createExpireTimeStamp(response.expires_in));
        setStep(AuthStepsEnum.CONFIRM_TERMS);
      } catch {
        setAuthError(PaywallAuthErrorCodes.GOOGLE_SIGN_IN_FAILED);
      }
    },
    onError: () => {
      setAuthError(PaywallAuthErrorCodes.GOOGLE_SIGN_IN_FAILED);
    },
  });

  const loginWithGoogle = () => {
    //googleLogin is hook
    googleLogin();
  };

  const loginWithApple = async () => {

    try {
      /* use Apple’s JavaScript SDK (https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js) */
      (window as any).AppleID.auth.init({
        clientId: APPLE_CLIENT_ID,
        redirectURI: APPLE_REDIRECT_URI,
        scope: 'email name',
        usePopup: true,
      });

      const response: AppleSignInResponse = await (window as any).AppleID.auth.signIn();

      const { authorization } = response;
      const { code, id_token } = authorization;

      if (!code || !id_token) {
        throw new Error('Missing authorization code or ID token.');
      }

      const decodedToken = decodeJWT(id_token);
      const expirationTime = decodedToken && decodedToken.exp;

      if (!expirationTime) {
        throw new Error('Missing expiration time in ID token.');
      }

      const res = await axios.post<{ data: ThirdPartyLoginResponse }>(postPaywallAuthAppleSignIn(), { thirdPartyToken: id_token });
      const resData = res.data && res.data.data

      if (!resData) {
        throw new Error();
      }

      if ((window as any).AppleID && (window as any).AppleID.auth && (window as any).AppleID.auth.signInPopup) {
        (window as any).AppleID.auth.signInPopup.close();
      }

      if (!resData.isNewCustomer) {
        handleExistingCustomer(resData)
        return
      }

      setTokenResponse(resData.token);
      setTokenExpireTimeStamp(createExpireTimeStamp(expirationTime));
      setStep(AuthStepsEnum.CONFIRM_TERMS);
    } catch (error) {
      setAuthError(error.message || PaywallAuthErrorCodes.APPLE_SIGN_IN_FAILED);
      return false;
    }
  };

  const thirdPartyLogin = async (isAgree: boolean) => {
    if (tokenExpireTimeStamp && (Date.now() > tokenExpireTimeStamp.getTime())) {
      setAuthError(PaywallAuthErrorCodes.TOKEN_EXPIRED)
      return
    }
    try {
      const response = await axios.post<any>(postPaywallAuthThirdPartyEndSignIn(), { token: tokenResponse, isAgree });
      handleLoginSuccess(response.data.data)
    } catch (error) {
      setAuthError(error.response && error.response.data && error.response.data.message || PaywallAuthErrorCodes.THIRD_PARTY_LOGIN_FAILED);
    }
  }

  const sendEmailForLogin = async (userEmail: string, agreesTerms?: boolean): Promise<boolean> => {
    try {
      const data: any = { email: userEmail.trim() }

      if (type === "signup") {
        if (!agreesTerms) {
          setAuthError(PaywallAuthErrorCodes.SIGNUP_NO_AGREEMENT);
          return false
        }
        data.agreesTerms = agreesTerms
      }

      if (!validateEmail(userEmail)) {
        setAuthError(PaywallAuthErrorCodes.INVALID_EMAIL);
        return false;
      }

      const recaptchaToken = await generateRecaptchaToken(type);
      const response = await axios.post(type === "login" ? postPaywallSignedIn(recaptchaToken) : postPaywallSignedUp(recaptchaToken), data);
      if (response.data) {
        setEmail(userEmail);
        setStep(AuthStepsEnum.PASSWORD);
        setAuthError(null);
        return true;
      }
      return false;
    } catch (error) {
      console.error(error)
      setAuthError(error.response && error.response.data && error.response.data.message || PaywallAuthErrorCodes.FAILED_TO_SEND_OTP);
      return false;
    }
  };

  const submitPassword = async (password: string): Promise<boolean> => {
    if (!email) {
      setAuthError(PaywallAuthErrorCodes.INVALID_EMAIL);
      return false;
    }

    try {
      const recaptchaToken = await generateRecaptchaToken(type + "_end");
      const endpoint = type === "signup" ? postPaywallSignedUpEnd(recaptchaToken) : postPaywallSignedInEnd(recaptchaToken);
      const response = await axios.post<any>(endpoint, { email, password });
      if (response.data) {
        handleLoginSuccess(response.data.data)
        return true;
      }
      return false;
    } catch (error) {
      setAuthError(error.response && error.response.data && error.response.data.message || PaywallAuthErrorCodes.AUTHENTICATION_FAILED);
      return false;
    }
  };
  const resetAuth = () => {
    setAuthError(null);
    setStep(AuthStepsEnum.EMAIL);
    setEmail(null);
    setTokenExpireTimeStamp(null)
    setTokenResponse(null)
  };

  return {
    authError,
    step,
    loginWithApple,
    loginWithGoogle,
    thirdPartyLogin,
    sendEmailForLogin,
    submitPassword,
    resetAuth,
  };
};
