require("./ctech_calcalist_site_calcalistBuzzComponenta.less")
import * as classNames from "classnames"
import { BuzzItemsList } from "./buzzItemsList"
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink"
import { LinkTargetProperties } from "../../../../../global/components/linkSettings/interfaces"

enum BuzzTabsEnum {
    Today,
    Weekly
}

interface SiteCalcalistBuzzComponentaProps {
    upperLinkHref: string
    bottomLinkHref: string
    bottomLinkTitle: string
    data: any
    upperLinkTargetProperties: LinkTargetProperties
    bottomLinkTargetProperties: LinkTargetProperties
    includeAd: boolean
}

interface SiteCalcalistBuzzComponentaState {
    selectedTab: BuzzTabsEnum
    wordsTranslations: any
}

export class SiteCalcalistBuzzComponenta extends React.Component<{ isLTR?: boolean } & SiteCalcalistBuzzComponentaProps, SiteCalcalistBuzzComponentaState>{
    static siteScriptName = "SiteCalcalistBuzzComponenta"
    constructor(props) {
        super(props)
        this.state = {
            selectedTab: BuzzTabsEnum.Today,
            wordsTranslations: {
                "he": {
                    "Buzz": "באזז",
                    "Daily": "היום",
                    "Weekly": "השבוע",
                    "Most popular": "הנצפות ביותר"
                },
                "en": {
                    "Buzz": "Buzz",
                    "Daily": "Daily",
                    "Weekly": "Weekly",
                    "Most popular": "Most popular"
                }

            }
        }
    }


    private onTodayTabClicked = (e) => {
        this.setSelectedTab(BuzzTabsEnum.Today)
    }

    private onWeeklyTabClicked = (e) => {
        this.setSelectedTab(BuzzTabsEnum.Weekly)
    }

    private setSelectedTab = (tabId: number) => {
        this.setState({ selectedTab: tabId })
    }

    private getTranslation = (lang, text: string) => {
        let obj = this.state.wordsTranslations[lang]
        let textToShow = obj[text]
        return textToShow
    }

    public render() {
        const { selectedTab } = this.state
        const { data, bottomLinkTitle, upperLinkHref, bottomLinkHref, upperLinkTargetProperties, bottomLinkTargetProperties, includeAd, isLTR } = this.props
        const todayTabClassNames = classNames("tab", { "active": selectedTab === BuzzTabsEnum.Today })
        const weeklyTabClassNames = classNames("tab", { "active": selectedTab === BuzzTabsEnum.Weekly })
        const itemsToShow = selectedTab ? data["last_week_data"] : data["last_day_data"]
        const componetaHeight = isLTR ? (includeAd ? 515 : 454) : (includeAd ? 385 : 338)
        return (
            <div className={classNames("CalcalistBuzzComponenta", { "Ctech": isLTR })} style={{ height: componetaHeight + "px" }}>
                <div className="upperContainer">
                    <div className="titlesWrapper">
                        <HyperLinkBuilder linkTarget={upperLinkTargetProperties} href={upperLinkHref} isDataTbLink>
                            <div className="logo"></div>
                        </HyperLinkBuilder>
                        <span className="mainTitle" data-tb-title=""><h3>{this.getTranslation(isLTR ? "en" : "he", "Buzz")}</h3></span>
                        <span className="separator"></span>
                        <span className="secondaryTItle" data-tb-description=""><h3>{this.getTranslation(isLTR ? "en" : "he", "Most popular")}</h3></span>
                    </div>
                    <div className="tabsWrapper">
                        <div className={todayTabClassNames} onClick={this.onTodayTabClicked}>{this.getTranslation(isLTR ? "en" : "he", "Daily")}</div>
                        <div className={weeklyTabClassNames} onClick={this.onWeeklyTabClicked}>{this.getTranslation(isLTR ? "en" : "he", "Weekly")}</div>
                    </div>
                </div>
                <BuzzItemsList items={itemsToShow} includeAd={includeAd} isDotShow={false} isCtechMobile={false}/>
                <div className="bottomLinkTitle">
                    <HyperLinkBuilder linkTarget={isLTR ? upperLinkTargetProperties : bottomLinkTargetProperties} href={isLTR ? upperLinkHref : bottomLinkHref} isDataTbLink>
                        <span className="bottomLinkText no-small-vp" data-tb-description="">{bottomLinkTitle}</span>
                        {isLTR && <span className="no-small-vp"> </span>}
                        {isLTR && <img className="buzzMoreArrow no-small-vp" src="https://images1.calcalist.co.il/images/1280/Buzzmore.png" />}
                    </HyperLinkBuilder>
                </div>
            </div>
        )
    }

}
