import { useRef, useEffect } from "react";
import { usePurchase } from "../../../../../../global/paywall/usePurchase";
import { SubscriptionPlan } from "./calcalistPaywallCheckoutMobilePlans"

interface CalcalistPaywallCheckoutPaymentDetailsProps {
    nextStep: () => void
    selectedPlan: SubscriptionPlan
}


const CalcalistPaywallCheckoutMobilePaymentDetails = (props: CalcalistPaywallCheckoutPaymentDetailsProps) => {
    const {selectedPlan} = props;
    const { purchaseStatus, paymentUrl, error, resetPurchase } = usePurchase(selectedPlan.productUniqueId);
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const { title = "Default Title", price = 0 } = props.selectedPlan || {};




    useEffect(() => {
        if (purchaseStatus === "completed") {
            props.nextStep()
        }
    }, [purchaseStatus])
    
    return (
        <div className="CalcalistPaywallCheckoutPaymentDetails">
            <h1 className="title">פרטי תשלום</h1>
            <div className="payment-details-container">
                <div className="payment-details-checkout">
                    <div className="payment-details-plan">
                        <div className="row"><span>מנוי</span><span>{title}</span></div>
                        <div className="row"><span>תשלום</span><span>{price.toFixed(2)} ש"ח</span></div>
                    </div>
                    <div className="checkout-summary">
                        <div className="row bold"> <span>לחיוב</span><span>{price.toFixed(2)} ש"ח</span></div>
                    </div>
                </div>
                <div className="creditcard-details-container">
                    <div className="title">הזינו פרטי אשראי</div>
                    <iframe ref={iframeRef as any} src={paymentUrl} />
                </div>

            </div>

        </div>
    );
};

export default CalcalistPaywallCheckoutMobilePaymentDetails;