import { useState } from "react";
import CalcalistPaywallCheckoutMobilePlans, { SubscriptionPlan } from "./calcalistPaywallCheckoutMobilePlans";
import CalcalistPaywallCheckoutMobileFinish from "./calcalistPaywallCheckoutMobilePaymentFinish";
import CalcalistPaywallCheckoutMobilePaymentDetails from "./calcalistPaywallCheckoutMobilePaymentDetails";
import CalcalistPaywallCheckoutMobilePersonalDetails from "./calcalistPaywallCheckoutMobilePersonalDetails";
 
export const CalcalistPaywallCheckoutMobileComponenta = ({ isMobile }: { isMobile?: boolean }) => {

    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
    const [selectedPlan,setPlan] = useState<SubscriptionPlan>(null);

    enum CheckoutStep {
        SELECT_PLAN = "בחירת מסלול",
        PERSONAL_DETAILS = "פרטים אישיים",
        PAYMENT_DETAILS = "פרטי תשלום",
        FINISH = "סיום",
    }

    const steps = Object.values(CheckoutStep);


    const nextStep = () => {
        setCurrentStepIndex(Math.min(currentStepIndex + 1, steps.length - 1));
      };
      
    const prevStep = () => {
        setCurrentStepIndex( Math.max(currentStepIndex - 1, 0));
    };

    const renderStepContent = () => {
        switch (steps[currentStepIndex]) {
            case CheckoutStep.SELECT_PLAN:
                return <CalcalistPaywallCheckoutMobilePlans nextStep={nextStep} setPlan={setPlan} />;
            case CheckoutStep.PERSONAL_DETAILS:
                return <CalcalistPaywallCheckoutMobilePersonalDetails nextStep={nextStep} prevStep={prevStep} />;
            case CheckoutStep.PAYMENT_DETAILS:
                return <CalcalistPaywallCheckoutMobilePaymentDetails nextStep={nextStep} prevStep={prevStep} selectedPlan={selectedPlan} />;
            case CheckoutStep.FINISH:
                return <CalcalistPaywallCheckoutMobileFinish nextStep={nextStep} />;
            default:
                return null;
        }
    };



    return (
        <div className="CalcalistPaywallCheckoutComponenta">
            <div className="gradient-background"></div>
            <div className="calcalist-digital-edition-text">המהדורה הדיגיטלית</div>
            {renderStepContent()}
        </div>
    )
}

CalcalistPaywallCheckoutMobileComponenta.siteScriptName = "CalcalistPaywallCheckoutMobileComponenta"
