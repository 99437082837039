import { AodFloatingPlayerProps } from "./aodFloatingPlayer";
import {
    enablePageRefresh,
    disablePageRefresh,
} from "../../../siteWidgets/siteWidgetUtils";
import { isWcmEditor } from "config/serverConfig";
import { ImaAd } from "./imaAd";
import { injectArticleComponentaContext } from "../../../commonComponents/base/injectArticleComponentaContext";
import { ArticleComponentaContext } from "../../../interfaces";
import { ArticleData } from "../../../../articlePage/stateInterfaces";

export interface MediaMetadata {
    // from https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/wicg-mediasession/index.d.ts
    // Media's title.
    title?: string;
    // Media's artist.
    artist?: string;
    // Media's album.
    album?: string;
}

declare var MediaMetadata: {
    prototype: MediaMetadata;
    new (init?: MediaMetadata): MediaMetadata;
};

declare var Hls: any;

export enum TogglePlayAction {
    "PLAY",
    "PAUSE",
}
export interface AudioRendererProps {
    isAudioPlaying: boolean;
    isAdPlaying?: boolean;
    onTogglePlayPause: () => TogglePlayAction;
    onRefChanged?: (elementRef) => void;
}

interface AudioPlayButtonState {
    isAudioPlaying: boolean;
    isFloatingPlayerVisible: boolean;
    playbackTime: number;
    isControlsDisabled: boolean;
    isSeeking: boolean;
    isMuted: boolean;
    adDuration: number;
    adPlaybackTime: number;
    playedAdOnFirstPlay: boolean;
    loadingSource: boolean;
}

interface AudioPlayButtonProps {
    audioUrl: string;
    durationSeconds: number;
    floatingPlayerRenderer: (
        props: AodFloatingPlayerProps
    ) => React.ReactElement<AodFloatingPlayerProps>;
    renderer: (
        props: AudioRendererProps
    ) => React.ReactElement<AudioRendererProps>;
    shouldAppearOnComponentOutOfView?: boolean;
    browserDisplayMediaMetaData?: MediaMetadata;
    startPostion?: number;
    adTag?: string;
    isAdPlaying?: boolean;
    onAdPlayingStateChange?: (adPlayingState: boolean) => void;
    onEnd?: () => void;
    articleData?: ArticleData;
    title?: string,
    articleLink?: string;
    isLive?: string;
}

declare var window: Window & {
    wcmAudioPlayer: AudioPlayButton;
};

export class AudioPlayButton extends React.Component<
    AudioPlayButtonProps,
    AudioPlayButtonState
> {
    private audioElement: HTMLAudioElement = RUNNING_IN_SERVER
        ? null
        : new Audio();
    private intersectionObserver: IntersectionObserver;
    private hls: any;
    private seekTo = -1;
    private imaAd: ImaAd = null;

    constructor(props: AudioPlayButtonProps) {
        super(props);
        this.state = {
            isAudioPlaying: false,
            isFloatingPlayerVisible: false,
            playbackTime: 0,
            isControlsDisabled: true,
            isSeeking: false,
            isMuted: false,
            adDuration: 0,
            adPlaybackTime: 0,
            playedAdOnFirstPlay: false,
            loadingSource: false,
        };
    }

    componentDidMount() {
        if (isWcmEditor()) return;
        if (
            typeof Hls !== "undefined" &&
            Hls.isSupported() &&
            this.props.audioUrl.endsWith(".m3u8")
        ) {
            this.hls = new Hls();
        }
        this.audioElement.addEventListener("ended", this.handleAudioEnded);
        this.audioElement.addEventListener("error", this.handleAudioError);
        this.audioElement.addEventListener("pause", this.handleAudioPause);
        this.audioElement.addEventListener("play", this.handleAudioPlay);
        this.audioElement.addEventListener(
            "timeupdate",
            this.handleAudioTimeUpdate
        );
        this.audioElement.addEventListener("canplay", this.handleAudioCanplay);
        this.audioElement.addEventListener("seeking", this.handleAudioSeeking);
        this.audioElement.addEventListener("seeked", this.handleAudioSeeked);
    }

    componentWillUnmount() {
        if (isWcmEditor()) return;
        this.audioElement.removeEventListener("ended", this.handleAudioEnded);
        this.audioElement.removeEventListener("error", this.handleAudioError);
        this.audioElement.removeEventListener("pause", this.handleAudioPause);
        this.audioElement.removeEventListener("play", this.handleAudioPlay);
        this.audioElement.removeEventListener(
            "timeupdate",
            this.handleAudioTimeUpdate
        );
        this.audioElement.removeEventListener(
            "canplay",
            this.handleAudioCanplay
        );
        this.audioElement.removeEventListener(
            "seeking",
            this.handleAudioSeeking
        );
        this.audioElement.removeEventListener("seeked", this.handleAudioSeeked);
        this.audioElement.pause();
        this.intersectionObserver && this.intersectionObserver.disconnect();
    }

    componentDidUpdate(prevProp: AudioPlayButtonProps) {
        if (isWcmEditor()) return;
        if (this.props.audioUrl !== prevProp.audioUrl) {
            this.changeSrc(this.props.audioUrl);
        }
        this.handlePercentagePlayedEvent();
    }

    private handlePercentagePlayedEvent = () => {
        const { durationSeconds } = this.props;
        const { playbackTime } = this.state;
        const percentagePlayed = Math.floor(
            (playbackTime / durationSeconds) * 100
        );
        if (percentagePlayed === 25) {
            this.pushGA4Event("media_watch_25_percent");
        } else if (percentagePlayed === 50) {
            this.pushGA4Event("media_watch_50_percent");
        } else if (percentagePlayed === 75) {
            this.pushGA4Event("media_watch_75_percent");
        }
    };

    /**
     * Pauses video content and sets up ad UI.
     */
    private onContentPauseRequested = () => {
        this.audioElement.pause();
        this.props.onAdPlayingStateChange(true);
    };

    /**
     * Resumes video content and removes ad UI.
     */
    private onContentResumeRequested = () => {
        this.props.onAdPlayingStateChange(false);
        this.audioElement.play();
    };

    private onAdProgressUpdate = (currentTime) => {
        this.setState({ adPlaybackTime: currentTime });
    };

    private updateAdDuration = (duration: number) => {
        this.setState({ adDuration: duration });
    };

    public isPlaying = () => {
        return this.state.isAudioPlaying;
    };

    onPlayBtnClick = () => {
        const { isAudioPlaying, playedAdOnFirstPlay } = this.state;
        const { audioUrl, browserDisplayMediaMetaData, isAdPlaying } =
            this.props;

        if (isWcmEditor()) return;

        if (isAudioPlaying) {
            this.imaAd && this.imaAd.pauseAd();
            this.audioElement.pause();
            this.pushGA4Event("media_pause");
            return TogglePlayAction.PAUSE;
        } else {
            if (!this.audioElement.getAttribute("data-src-loaded")) {
                this.audioElement.setAttribute("data-src-loaded", "1");
                this.changeSrc(audioUrl);
            }
            if (browserDisplayMediaMetaData && "mediaSession" in navigator) {
                //@ts-ignore
                navigator.mediaSession.metadata = new MediaMetadata(
                    browserDisplayMediaMetaData
                );
            }
            if (window.wcmAudioPlayer !== this) {
                if (window.wcmAudioPlayer) {
                    window.wcmAudioPlayer.stop();
                }
                window.wcmAudioPlayer = this;
            }
            if (this.imaAd && !playedAdOnFirstPlay) {
                this.imaAd.playAds();
                this.pushGA4Event("media_start_preroll");
                this.setState({ playedAdOnFirstPlay: true });
            } else if (isAdPlaying) {
                this.imaAd.resumeAd();
            } else {
                this.pushGA4Event("media_completed_preroll");
                let playPromise = this.audioElement.play();
                this.setState({ loadingSource: true });

                if (playPromise !== undefined) {
                    playPromise
                        .then((_) => {
                            this.setState({ loadingSource: false });
                            this.pushGA4Event("media_start");
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            }
            this.pushGA4Event("media_click_play");
            return TogglePlayAction.PLAY;
        }
    };

    public stop = () => {
        this.setState({
            isFloatingPlayerVisible: false,
            playedAdOnFirstPlay: false,
        });

        if (this.props.isAdPlaying) {
            this.imaAd && this.imaAd.pauseAd();
            this.props.onAdPlayingStateChange(false);
            this.handleAudioPause();
        } else {
            this.audioElement.pause();
            this.audioElement.currentTime = 0;
        }

        this.imaAd && this.imaAd.restartAds(true);
    };

    public play = (shouldPlayAd?: boolean) => {
        if (this.imaAd && shouldPlayAd) {
            this.imaAd.playAds();
            this.pushGA4Event("media_start_preroll");
        } else {
            this.audioElement.play();
        }
    };

    public changeSrc = (newSrc: string) => {
        const wasPlaying = this.state.isAudioPlaying;
        const startPosition = this.props.startPostion;

        if (this.hls) {
            this.hls.destroy();
            this.hls = new Hls({ startPosition: startPosition });
            this.hls.loadSource(newSrc);
            this.hls.attachMedia(this.audioElement);
        } else {
            this.audioElement.src = newSrc;
            this.seekTo = startPosition;
        }

        if (wasPlaying) {
            let playPromise = this.audioElement.play();
            this.setState({ loadingSource: true });

            if (playPromise !== undefined) {
                playPromise
                    .then((_) => {
                        this.setState({ loadingSource: false });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        }
    };

    private onRenderrRefUpdated = (ref) => {
        if (this.props.shouldAppearOnComponentOutOfView && ref) {
            this.intersectionObserver = new IntersectionObserver(
                ([entry]) => {
                    this.setState((state) => ({
                        isFloatingPlayerVisible:
                            state.isAudioPlaying && !entry.isIntersecting,
                    }));
                },
                { threshold: 0.5 }
            );
            this.intersectionObserver.observe(ref);
        }
    };

    private handleAudioTimeUpdate = () =>
        this.setState({ playbackTime: this.audioElement.currentTime });

    private handleAudioPlay = () => {
        this.setState({ isAudioPlaying: true });
        if (!this.props.shouldAppearOnComponentOutOfView) {
            this.setState({ isFloatingPlayerVisible: true });
        }
        disablePageRefresh();
    };

    private handleAudioPause = () => {
        this.setState({ isAudioPlaying: false });
        enablePageRefresh();

        this.pushGA4Event("media_pause");
    };

    private handleAudioError = () =>
        this.setState({
            isAudioPlaying: false,
            isFloatingPlayerVisible: false,
        });

    private handleAudioEnded = () => {
        const { onEnd } = this.props;

        if (onEnd) {
            this.setState({ isAudioPlaying: true }, () => onEnd());
        } else {
            this.setState({ isAudioPlaying: false });
        }
        this.pushGA4Event("media_finish");
    };

    private pushGA4Event = (eventName: string) => {
        if (typeof window == "undefined" || !window.dataLayer || !this.props.articleData) return;

        const { articleData, durationSeconds, articleLink, audioUrl, isLive, browserDisplayMediaMetaData } =
            this.props;
        const { articleId, title, isMarketingContent, publishedLink } = articleData;
        let link = articleLink || publishedLink || "";
        let mediaTitle =browserDisplayMediaMetaData ? browserDisplayMediaMetaData.title :  (title || "");
        let mediaAlbom = browserDisplayMediaMetaData ? browserDisplayMediaMetaData.album : "";
        window.dataLayer.push({
            event: eventName,
            article_id: articleId ? articleId : "",
            sponsored_content: isMarketingContent,
            // word_count: wordCount,
            media_type: `podcast`,
            media_length: durationSeconds,
            media_title: mediaTitle,
            media_id: link.split("/").pop(),
            media_location: "article page",
            media_player_name: "native ynet audio player",
            media_url: audioUrl,
            media_autoplay: "false",
            media_live: isLive ? isLive : "false",
            media_program_host: mediaAlbom,
            media_program_name: mediaAlbom,
            media_program_chapter_number: `${mediaAlbom} ${mediaTitle}`,

        });
    };

    private handleAudioCanplay = () => {
        if (!this.hls && this.seekTo > -1) {
            this.audioElement.currentTime = this.seekTo;
            this.seekTo = -1;
        }

        this.setState({ isControlsDisabled: false });
    };

    private handleAudioSeeking = () => this.setState({ isSeeking: true });

    private handleAudioSeeked = () => this.setState({ isSeeking: false });

    public handlePlayerSeeked = (playbackTime: number) => {
        this.setState({ playbackTime }, () => {
            this.audioElement.currentTime = this.state.playbackTime;
        });
    };

    private handleMuteSwitch = () => {
        this.setState({ isMuted: !this.state.isMuted }, () => {
            if (this.state.isMuted) {
                this.audioElement.muted = true;
                this.imaAd && this.imaAd.setAdVolume(0);
                this.pushGA4Event("media_mute");
            } else {
                this.audioElement.muted = false;
                this.imaAd && this.imaAd.setAdVolume(1);
                this.pushGA4Event("media_unmute");
            }
        });
    };

    private setImaAd = (ref: ImaAd) => {
        if (this.props.adTag) this.imaAd = ref;
    };

    private handleAdEnded = () => {
        this.setState({ isAudioPlaying: true });
        this.pushGA4Event("media_finish_preroll");
    };

    render() {
        const {
            isAudioPlaying,
            isFloatingPlayerVisible,
            playbackTime,
            isControlsDisabled,
            isSeeking,
            isMuted,
            adDuration,
            adPlaybackTime,
            loadingSource,
        } = this.state;
        const { durationSeconds, floatingPlayerRenderer, renderer, adTag } =
            this.props;
        if (RUNNING_IN_SERVER || isWcmEditor()) {
            return (
                <div className="audio-play-button-wrapper">
                    {renderer({
                        isAudioPlaying,
                        onTogglePlayPause: this.onPlayBtnClick,
                        onRefChanged: this.onRenderrRefUpdated,
                    })}
                </div>
            );
        }
        return (
            <div className="audio-play-button-wrapper">
                <ImaAd
                    ref={this.setImaAd}
                    adTag={adTag}
                    audioElement={this.audioElement}
                    onContentPauseRequested={this.onContentPauseRequested}
                    onContentResumeRequested={this.onContentResumeRequested}
                    onAdProgressUpdate={this.onAdProgressUpdate}
                    updateAdDuration={this.updateAdDuration}
                    onAdPlayStarted={this.handleAudioPlay}
                    onAdPlayPause={this.handleAudioPause}
                    onAdPlayComplete={this.handleAdEnded}
                />
                {renderer({
                    isAudioPlaying,
                    onTogglePlayPause: this.onPlayBtnClick,
                    onRefChanged: this.onRenderrRefUpdated,
                })}
                {floatingPlayerRenderer({
                    durationSeconds,
                    playbackTime,
                    onClose: this.stop,
                    isAudioPlaying,
                    audioElement: this.audioElement,
                    isFloatingPlayerVisible: isFloatingPlayerVisible,
                    isControlsDisabled,
                    isSeeking,
                    isMuted,
                    adDuration,
                    adPlaybackTime,
                    loadingSource,
                    onseeked: this.handlePlayerSeeked,
                    handleMuteSwitch: this.handleMuteSwitch,
                    onTogglePlayPause: this.onPlayBtnClick,
                })}
            </div>
        );
    }
}
