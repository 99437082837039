
interface CalcalistPaywallCheckoutPlansProps {
    nextStep: () => void
    setPlan: (selectedPlan: SubscriptionPlan) => void
}

enum ProductsUniqueIds {
    MONTHLY_PASS = 'monthlyPass',
    YEARLY_PASS = 'yearlyPass',
  };
export interface SubscriptionPlan {
    title: string;
    price: number;
    frequency: string;
    buttonText: string;
    cancelText: string;
    className: string;
    productUniqueId: ProductsUniqueIds
}

export const plansObj = {
    "MONTHLY": {
        title: "חודשי",
        price: 29.90,
        frequency: "בתשלום חודשי מתחדש",
        buttonText: "להצטרפות",
        cancelText: "ניתן לבטל את המינוי בכל עת לתנאי השימוש",
        className: "monthly-plan",
        productUniqueId: 'monthlyPass'
    },
    "YEARLY": {
        title: "שנתי",
        price: 299.90,
        frequency: "בתשלום שנתי מתחדש",
        buttonText: "להצטרפות",
        cancelText: "ניתן לבטל את המינוי בכל עת לתנאי השימוש",
        className: "yearly-plan",
        productUniqueId: 'yearlyPass'
    }
}

const CalcalistPaywallCheckoutPlans = (props: CalcalistPaywallCheckoutPlansProps) => {
    const { nextStep, setPlan } = props;



    const handleNextStep = (selectedPlan) => {
        setPlan(selectedPlan)
        nextStep()
    }
    return (
        <div className="CalcalistPaywallCheckoutPlans">
            <div className="checkout-title">
                רוצים להמשיך לקרוא ללא הגבלה את המהדורה הדיגיטלית?
                לרכישת מינוי של עיתון כלכליסט בדיגיטל
            </div>
            <div className="plans-contaienr">
                {
                    Object.keys(plansObj).map((key) => {
                        const plan = plansObj[key]
                        return (
                            <div key={key} className={`plan-box ${plan.className}`}>
                                <div className="plan-title">מסלול חיוב {plan.title} </div>
                                <div className="plan-price">{plan.price.toFixed(2)}</div>
                                <div className="plan-price-frequency">{plan.frequency}</div>
                                <button className="plan-button" onClick={() => handleNextStep(plan)}>{plan.buttonText}</button>
                                <div className="plan-cancel-text"><span>ניתן לביטול בכל עת</span> <br/> <a href="https://www.calcalist.co.il/home/0,7340,L-3856,00.html">לתנאי השימוש</a></div>
                            </div>
                        )
                    })
                }

            </div>
            <div className="bottom-logo"></div>
        </div>
    );
};

export default CalcalistPaywallCheckoutPlans;