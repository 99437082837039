import { useState, useEffect } from "react"
import { getPersonalCustomerDetails, updatePersonalCustomerDetails } from "../../../../../../global/paywall/paywallApiUrls";
import * as axios from "axios";
import * as classNames from "classnames"
interface CalcalistPaywallCheckoutPersonalDetailsProps {
    nextStep: () => void
}
interface CalcalistPaywallAccessDataResponse {
    data: {
        id: number;
        email: string;
        firstName: string | null;
        lastName: string | null;
        personalId: string | null;
        phoneNumber: string | null;
    };
    requestId: string;
    error: any;
}


const CalcalistPaywallCheckoutMobilePersonalDetails = (props: CalcalistPaywallCheckoutPersonalDetailsProps) => {
    const { nextStep } = props;
    const [formData, setFormData] = useState<any>({
        email: { id: 1, name: "email", type: "text", label: "משתמש", disabled: true, order: 1, value: "" },
        firstname: { id: 2, name: "firstname", type: "text", label: "שם פרטי", order: 2, value: "" },
        lastname: { id: 3, name: "lastname", type: "text", label: "שם משפחה", order: 3, value: "" },
        idNumber: { id: 5, name: "idNumber", type: "text", label: "ת.ז", value: "" },
        phone: { id: 4, name: "phone", type: "text", label: "נייד", value: "" },
        termsOfuse: {
            id: 6, name: "termsOfuse", type: "checkbox",
            label: "מסכים/ה לתנאי השימוש, מדיניות פרטיות, וקבלת פניות שיווקיות כולל טלפוניות. הודעות תפעוליות ימשיכו להישלח בתקופת המינוי גם לאחר הסרתי מרשימת מדוורים.",
            value: false
        },
    });

    const [generalError, setGeneralError] = useState("");
    const [errors, setErrors] = useState({});

    const validationSchema = {
        firstname: {
            required: true,
            message: "שדה חובה",
            validate: (value: string) => /^[a-zA-Z]+$/.test(value) || "שם פרטי יכול להכיל רק אותיות",
        },
        lastname: {
            required: true,
            message: "שדה חובה",
            validate: (value: string) => /^[a-zA-Z]+$/.test(value) || "שם משפחה יכול להכיל רק אותיות",
        },
        phone: {
            required: true,
            message: "שדה חובה",
            validate: (value: string) => /^05\d{8}$/.test(value) || "מספר טלפון לא תקין",
        },
        idNumber: {
            required: true,
            message: "שדה חובה",
            validate: (value: string) => {
                const isValidIsraeliID = (id: string): boolean => {
                    if (!/^\d{9}$/.test(id)) return false;
                    const idArray = id.split("").map(Number);
                    let sum = 0;
                    for (let i = 0; i < 9; i++) {
                        let num = idArray[i] * (i % 2 === 0 ? 1 : 2);
                        sum += num > 9 ? num - 9 : num;
                    }
                    return sum % 10 === 0;
                };

                return isValidIsraeliID(value) || "תעודת זהות לא תקינה";
            }
        },
        termsOfuse: {
            required: true,
            message: "יש לאשר את תנאי השימוש",
            validate: (value: boolean) => value || "יש לאשר את תנאי השימוש",
        }

    };


    const validateField = (name, value) => {
        const schema = validationSchema[name];
        if (!schema) return "";
        if (schema.required) {
            if (typeof value === "boolean" && !value) return schema.message;
            if (typeof value === "string" && !value.trim()) return schema.message;
        }
        if (schema.validate) {
            return typeof schema.validate(value) === "boolean" ? "" : schema.validate(value);
        }
        return "";
    };


    const handleChange = (e) => {
        let { name, value } = e.target;

        if (name === "termsOfuse") {
            value = e.target.checked;
        }

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: { ...prevFormData[name], value },
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: validateField(name, value),
        }));

    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        let newErrors = {};
        let isValid = true;

        Object.keys(formData).forEach((key) => {
            const errorMessage = validateField(key, formData[key].value);
            if (errorMessage) {
                newErrors[key] = errorMessage;
                isValid = false;
            }
        });

        setErrors(newErrors);

        if (!isValid) {
            console.log("Form has errors:", newErrors);
            return;
        }

        const response = await updateUserData(formData);
        if (response) {
            console.log("Form submitted successfully:", formData);
            nextStep();
        }
    };


    useEffect(() => {
        async function fetchData() {
            const { data } = await getUserData(getPersonalCustomerDetails());
            setFormData(prevFormData => ({
                ...prevFormData,
                email: { ...prevFormData.email, value: data.email || "" },
                firstname: { ...prevFormData.firstname, value: data.firstName || "" },
                lastname: { ...prevFormData.lastname, value: data.lastName || "" },
                idNumber: { ...prevFormData.idNumber, value: data.personalId || "" },
                phone: { ...prevFormData.phone, value: data.phoneNumber || "" },
            }));
        }
        fetchData();
    }, [])

    const getUserData = async (url: string): Promise<CalcalistPaywallAccessDataResponse> => {
        try {
            const response = await axios.get<CalcalistPaywallAccessDataResponse>(url);
            return response.data;
        } catch (error) {
            console.error(error);
            setGeneralError("משהו השתבש, נסו שוב מאוחר יותר");

        }
    };


    const updateUserData = async (data) => {

        const updatedDetails = {
            details: {
                firstName: data.firstname.value,
                lastName: data.lastname.value,
                personalId: data.idNumber.value,
                phoneNumber: data.phone.value,
            }
        };
        try {
            const response = await axios.post(updatePersonalCustomerDetails(), updatedDetails);
            if (response.status === 200) {
                console.log("Data updated successfully");
                return response.data;
            }
        } catch (error) {
            console.error(error);
            setGeneralError("משהו השתבש, נסו שוב מאוחר יותר");
        }
    };


    return (
        <div className="CalcalistPaywallCheckoutPersonalDetails">
            <h1 className="title">הזינו פרטים אישיים</h1>
            <div className="user-email">{formData['email'].value}</div>
            <div className="personal-details-form">
                <form id="checkout-personal-details" onSubmit={handleSubmit}>
                    {Object.keys(formData).map((field) => {
                        const inputField = formData[field];
                        return <div
                            className={`input-field ${classNames({ 'terms': inputField.name === 'termsOfuse' })}`}
                            key={inputField.id}>
                            <input
                                placeholder={inputField.label}
                                disabled={inputField.disabled}
                                type={inputField.type}
                                name={inputField.name}
                                value={inputField.value}
                                onChange={handleChange}
                                className={errors[field] ? "error" : ""}
                                hidden={inputField.name === "email"}
                            />
                            {inputField.name === 'termsOfuse' && <label>{inputField.label}</label>}
                            {errors[field] && <span className="input-field-error">{errors[field]}</span>}
                        </div>
                    })}
                    <div className="general-error">{generalError}</div>
                    <button form="checkout-personal-details" className="submit-button" type="submit">המשך</button>
                </form>
            </div>

        </div>
    );
};

export default CalcalistPaywallCheckoutMobilePersonalDetails;