

import { useState } from "react";

type Tab = {
    label: string;
    content: React.ReactNode;
};

type TabsProps = {
    tabs: Tab[];
};


const CalcalistPaywallMyAccountTabs = ({ tabs }: TabsProps) => {
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <div className="CalcalistPaywallMyAccountTabs">
            <div role="tablist" className="tabs-header">
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        role="tab"
                        aria-selected={activeIndex === index}
                        className={`tab-button ${activeIndex === index ? "active" : ""}`}
                        onClick={() => setActiveIndex(index)}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
            <div role="tabpanel" className="tabs-content">
                {tabs[activeIndex].content}
            </div>
        </div>
    );
};

export default CalcalistPaywallMyAccountTabs