import { ArticleComponentasDictionary } from "../../../../../articlePage/stateInterfaces";
import { ArticleBlogData } from "../../../article/componentas/articleBlog/articleBlogData";
import { placeHeaderBelowTitle, setTitleFullWidth } from "../utils";

interface Props {
    posts: ArticleBlogData[];
    title: string;
    date: any;
    roofTitle: string
}

interface State {
    firstPostIndex: number;
}

export class SiteArticleBlogHeaderComponenta extends React.Component<
    Props,
    State
    > {
    public static siteScriptName = "SiteArticleBlogHeaderComponenta";

    constructor(props: Props) {
        super(props);
        this.state = {
            firstPostIndex: 0,
        };
    }

    componentDidMount() {
        // setTitleFullWidth();
        // placeHeaderBelowTitle();
    }

    private formatDate = () => {
        const { date } = this.props;

        // Convert the date string to a Date object
        const dateObj = new Date(date);

        // e.g. "28 בינואר, 2024"
        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
        };

        // comma placement on date string
        let count = 0;
        return dateObj.toLocaleDateString("he-IL", options).replace(/ /g, function (match) {
            count++;
            return count === 2 ? ", " : match;
        });
    };

    private handleTimelineItemClick = (e, post: ArticleBlogData) => {
        e.preventDefault();

        const headerHeight = -60;
        const ItemTopMargin = -10;
        const element = document.getElementById(post.blogId);
        const y = element.getBoundingClientRect().top + window.pageYOffset + headerHeight + ItemTopMargin;

        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    handleRightButtonClick = () => {
        if (this.state.firstPostIndex === 0 || this.props.posts.length < 5) {
            return;
        }

        this.setState((prevState) => {
            return {
                firstPostIndex: prevState.firstPostIndex - 1,
            };
        });
    };

    handleLeftButtonClick = () => {
        if (
            this.state.firstPostIndex === this.props.posts.length - 5 ||
            this.props.posts.length < 5
        ) {
            return;
        }

        this.setState((prevState) => {
            return {
                firstPostIndex: prevState.firstPostIndex + 1,
            };
        });
    };

    private getHowLongAgo = (date: string) => {
        const now = new Date();
        const diff = now.getTime() - new Date(date).getTime();
        const diffInMinutes = diff / 1000 / 60;
        const diffInHours = diffInMinutes / 60;
        const diffInDays = diffInHours / 24;
        const diffInWeeks = diffInDays / 7;
        const diffInMonths = diffInDays / 30;
        const diffInYears = diffInDays / 365;

        if (diffInMinutes < 60) {
            return `לפני ${Math.floor(diffInMinutes)} דק'`;
        } else if (diffInHours < 24) {
            return `לפני ${Math.floor(diffInHours)} שע'`;
        } else if (diffInDays < 2) {
            return "אתמול";
        } else if (diffInDays < 30) {
            return `לפני ${Math.floor(diffInDays)} ימים`;
        } else if (diffInDays < 365) {
            return `לפני ${Math.floor(diffInWeeks)} שבועות`;
        } else if (diffInDays < 365 * 2) {
            return `לפני ${Math.floor(diffInMonths)} חודשים`;
        } else if (diffInDays < 365 * 3) {
            return "שנה שעברה";
        } else {
            return `לפני ${Math.floor(diffInYears)} שנים`;
        }
    };

    public render() {
        const { posts, roofTitle } = this.props;
        const { firstPostIndex } = this.state;
        const postsToDisplay = posts.slice(firstPostIndex, firstPostIndex + 5);

        return (
            <div className="calcalist-article-blog-header">
                <div className="calcalist-article-blog-header-bg no-print" />
                <div className="article-blog-header-title">
                    <h1 className="article-blog-header-title-text">
                        {this.props.title}
                    </h1>
                    <div className="article-blog-header-title-date">
                        {this.formatDate()}
                    </div>
                </div>
                <div className="article-blog-header-timeline-wrapper">
                    <div className="article-blog-header-timeline-line" />
                    <div className="article-blog-timeline">
                        <div
                            className="article-blog-timeline-scroll right"
                            onClick={this.handleRightButtonClick}
                        />
                        {postsToDisplay.map((post, index) => (
                            <a
                                key={index}
                                className="article-blog-timeline-item"
                                onClick={(e) =>
                                    this.handleTimelineItemClick(e, post)
                                }
                            >
                                <div className="article-blog-timeline-item-age">
                                    <span className="article-blog-timeline-item-age-text">
                                        {this.getHowLongAgo(
                                            post.blogDate as any
                                        )}
                                    </span>
                                </div>
                                <div className="article-blog-timeline-item-title">
                                    {post.blogTitle}
                                </div>
                            </a>
                        ))}
                        <div
                            className="article-blog-timeline-scroll left"
                            onClick={this.handleLeftButtonClick}
                        />
                    </div>
                </div>
                {roofTitle && roofTitle.length > 0 && <div className="roofTitle">{roofTitle}</div>}
            </div>
        );
    }
}
