export class PopUp extends React.Component<{ onClose: () => void, loading: boolean }, {}> {
    public render() {
        const { loading } = this.props;
        if (loading) {
            return (
                <div className="redMailPopUp">
                    <div className="loading"></div>
                    <p className="title">لحظات وننتهي...</p>
                </div>
            )
        } else {
            return (
                <div className="redMailPopUp">
                    <div className="finish"></div>
                    <p className="title"> شكرًا لكم! رسالتكم قيد الفحص قبل المشاركة</p>
                    <p className="subTitle"></p>
                    <button className="popUpButton"><a href="/">العودة إلى الصفحة الرئيسية</a></button>
                </div>
            )
        }
    }

}



