import {
    enablePageRefresh,
    disablePageRefresh,
} from "../../../../siteWidgets/siteWidgetUtils";
import { ImaAd } from "../../../radioWidgets/floatingPlayer/imaAd";
import { ArticleData } from "../../../../../articlePage/stateInterfaces";

export interface AodFloatingPlayerProps {
    isAudioPlaying: boolean;
    audioElement: HTMLAudioElement;
    durationSeconds: number;
    isFloatingPlayerVisible: boolean;
    isSeeking: boolean;
    adDuration: number;
    adPlaybackTime: number;
    onClose: () => void;
    onTogglePlayPause: () => void;
}

export interface MediaMetadata {
    // from https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/wicg-mediasession/index.d.ts
    // Media's title.
    title?: string;
    // Media's artist.
    artist?: string;
    // Media's album.
    album?: string;
}

declare var MediaMetadata: {
    prototype: MediaMetadata;
    new(init?: MediaMetadata): MediaMetadata;
};

export interface AudioRendererProps {
    isAudioPlaying: boolean;
    isAdPlaying?: boolean;
    lang: string
    onTogglePlayPause: () => void;
    onRefChanged?: (elementRef) => void;
}

interface YnetLiveAudioPlayButtonState {
    isAudioPlaying: boolean;
    isFloatingPlayerVisible: boolean;
    isSeeking: boolean;
    adDuration: number;
    adPlaybackTime: number;
    playedAdOnFirstPlay: boolean;
    loadingSource: boolean;
}

interface YnetLiveAudioPlayButtonProps {
    audioUrl: string;
    durationSeconds: number;
    shouldAppearOnComponentOutOfView?: boolean;
    browserDisplayMediaMetaData?: MediaMetadata;
    adTag?: string;
    isAdPlaying?: boolean;
    startPostion?: number;
    programTitle: string
    isVideoFloatingPlayerVisible: boolean
    lang: string
    floatingPlayerRenderer: (
        props: AodFloatingPlayerProps
    ) => React.ReactElement<AodFloatingPlayerProps>;
    renderer: (
        props: AudioRendererProps
    ) => React.ReactElement<AudioRendererProps>;
    onAdPlayingStateChange?: (adPlayingState: boolean) => void;
    onVideoFloatingPlayerClose: () => void;
    articleData?: ArticleData;
    articleLink?: string;
}

declare var window: Window & {
    wcmAudioPlayer: YnetLiveAudioPlayButton;
};

declare var Hls: any

export class YnetLiveAudioPlayButton extends React.Component<YnetLiveAudioPlayButtonProps, YnetLiveAudioPlayButtonState> {
    private audioElement: HTMLAudioElement = RUNNING_IN_SERVER ? null : new Audio();
    private imaAd: ImaAd = null;
    private hls: any
    private intersectionObserver: IntersectionObserver;

    constructor(props: YnetLiveAudioPlayButtonProps) {
        super(props);
        this.state = {
            isAudioPlaying: false,
            isFloatingPlayerVisible: false,
            isSeeking: false,
            adDuration: 0,
            adPlaybackTime: 0,
            playedAdOnFirstPlay: false,
            loadingSource: false,
        };
    }

    componentDidMount() {
        if (typeof Hls !== "undefined" && Hls.isSupported() && this.props.audioUrl.endsWith(".m3u8")) {
            this.hls = new Hls();
        }
        this.audioElement.addEventListener("ended", this.handleAudioEnded)
        this.audioElement.addEventListener("error", this.handleAudioError)
        this.audioElement.addEventListener("pause", this.handleAudioPause)
        this.audioElement.addEventListener("play", this.handleAudioPlay)
        this.audioElement.addEventListener("seeking", this.handleAudioSeeking)
        this.audioElement.addEventListener("seeked", this.handleAudioSeeked)
    }

    componentWillUnmount() {
        this.audioElement.removeEventListener("ended", this.handleAudioEnded);
        this.audioElement.removeEventListener("error", this.handleAudioError);
        this.audioElement.removeEventListener("pause", this.handleAudioPause);
        this.audioElement.removeEventListener("play", this.handleAudioPlay);
        this.audioElement.removeEventListener(
            "seeking",
            this.handleAudioSeeking
        );
        this.audioElement.removeEventListener("seeked", this.handleAudioSeeked);
        this.audioElement.pause();
        this.intersectionObserver && this.intersectionObserver.disconnect();
    }

    /**
     * Pauses video content and sets up ad UI.
     */
    private onContentPauseRequested = () => {
        this.audioElement.pause();
        this.props.onAdPlayingStateChange(true);
    };

    /**
     * Resumes video content and removes ad UI.
     */
    private onContentResumeRequested = () => {
        this.props.onAdPlayingStateChange(false);
        this.audioElement.play();
    };

    private onAdProgressUpdate = (currentTime) => {
        this.setState({ adPlaybackTime: currentTime });
    };

    private updateAdDuration = (duration: number) => {
        this.setState({ adDuration: duration });
    };

    public changeSrc = (newSrc: string) => {
        const wasPlaying = this.state.isAudioPlaying;
        const startPosition = this.props.startPostion;

        if (this.hls) {
            this.hls.destroy();
            this.hls = new Hls({ startPosition: startPosition });
            this.hls.loadSource(newSrc);
            this.hls.attachMedia(this.audioElement);
        } else {
            this.audioElement.src = newSrc;
        }
        if (wasPlaying) {
            let playPromise = this.audioElement.play();
            this.setState({ loadingSource: true });

            if (playPromise !== undefined) {
                playPromise
                    .then((_) => {
                        this.setState({ loadingSource: false });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        }
    };

    private onClosePlayingVideo = () => {
        const liveVideoElement: HTMLElement = document.getElementById("minimized-player");
        const playerElement: any = document.querySelector('.minimizedPlayer .fp-engine');
        liveVideoElement && liveVideoElement.classList.add("none");
        liveVideoElement && playerElement.pause();
    }

    onPlayBtnClick = () => {
        const { isAudioPlaying, playedAdOnFirstPlay } = this.state;
        const { audioUrl, browserDisplayMediaMetaData, isAdPlaying, onVideoFloatingPlayerClose, programTitle } = this.props;

        this.onClosePlayingVideo();

        if (isAudioPlaying) {
            this.handleAudioPlayerPause();
        } else {
            this.setState({ isFloatingPlayerVisible: true });
            onVideoFloatingPlayerClose();

            if (!this.audioElement.getAttribute("data-src-loaded")) {
                this.audioElement.setAttribute("data-src-loaded", "1")
                this.changeSrc(audioUrl);
                this.analyticstFunc("Play - Audio Starts", programTitle);
            }
            if (browserDisplayMediaMetaData && 'mediaSession' in navigator) {
                //@ts-ignore
                navigator.mediaSession.metadata = new MediaMetadata(
                    browserDisplayMediaMetaData
                );
            }
            if (window.wcmAudioPlayer !== this) {
                if (window.wcmAudioPlayer) {
                    window.wcmAudioPlayer.stop();
                }
                window.wcmAudioPlayer = this;
            }
            if (this.imaAd && !playedAdOnFirstPlay) {
                this.imaAd.playAds();
                this.pushGA4Event("media_start_preroll");
                this.setState({ playedAdOnFirstPlay: true });
            } else if (isAdPlaying) {
                this.imaAd.resumeAd();
                this.analyticstFunc("Play - Ads", programTitle);
            } else {
                this.pushGA4Event("media_completed_preroll");
                let playPromise = this.audioElement.play();
                this.setState({ loadingSource: true });
                this.analyticstFunc("Resume", programTitle);

                if (playPromise !== undefined) {
                    playPromise
                        .then((_) => {
                            this.setState({ loadingSource: false });
                            this.pushGA4Event("media_start");
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            }
            this.pushGA4Event("media_click_play");
        }
    };

    public stop = () => {
        const { isAudioPlaying } = this.state;
        isAudioPlaying && this.onPlayBtnClick();
        this.audioElement.currentTime = 0;
        this.setState({ isFloatingPlayerVisible: false });
    };

    private onRenderrRefUpdated = (ref) => {
        if (this.props.shouldAppearOnComponentOutOfView && ref) {
            this.intersectionObserver = new IntersectionObserver(
                ([entry]) => {
                    this.setState((state) => ({
                        isFloatingPlayerVisible:
                            state.isAudioPlaying && !entry.isIntersecting,
                    }));
                },
                { threshold: 0.5 }
            );
            this.intersectionObserver.observe(ref);
        }
    };

    private handleAudioPlay = () => {
        this.setState({ isAudioPlaying: true });
        if (!this.props.shouldAppearOnComponentOutOfView) {
            this.setState({ isFloatingPlayerVisible: true });
        }
        disablePageRefresh();
    };

    private handleAudioPause = () => {
        this.setState({ isAudioPlaying: false });
        enablePageRefresh();
        this.pushGA4Event("media_pause");
    };

    private handleAudioError = () => this.setState({ isAudioPlaying: false, isFloatingPlayerVisible: false });

    private handleAudioEnded = () => this.setState({ isAudioPlaying: false });

    private pushGA4Event = (eventName: string) => {
        if (typeof window == "undefined" || !window.dataLayer) return;

        const { durationSeconds, audioUrl } = this.props;

        window.dataLayer.push({
            event: eventName,
            media_type: "audio",
            media_length: durationSeconds,
            media_title: "ynet live",
            media_location: "category page",
            media_player_name: "native ynet audio player",
            media_url: audioUrl,
            media_autoplay: "false",
            media_live: "LIVE",
        });
    };

    private analyticstFunc = (action: string, title: string) => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'GA_Event',
            Category: 'Audio',
            Action: action,
            Label: `LIVE_AUDIO#${title}`,
        });
    }

    private handleAudioSeeking = () => this.setState({ isSeeking: true });

    private handleAudioSeeked = () => this.setState({ isSeeking: false });

    private setImaAd = (ref: ImaAd) => {
        if (this.props.adTag) this.imaAd = ref;
    };

    private handleAdEnded = () => {
        this.setState({ isAudioPlaying: true });
        this.pushGA4Event("media_finish_preroll");
    }

    private handleAudioPlayerPause = () => {
        const {programTitle} = this.props;
        this.audioElement.pause();
        this.imaAd && this.imaAd.pauseAd();
        this.analyticstFunc("Pause", programTitle);
    }

    render() {
        const { isAudioPlaying, isFloatingPlayerVisible, isSeeking, adDuration, adPlaybackTime } = this.state;
        const { durationSeconds, floatingPlayerRenderer, renderer, adTag, isVideoFloatingPlayerVisible, lang } = this.props;
        isVideoFloatingPlayerVisible && this.handleAudioPlayerPause();

        return (
            <div className="audio-play-button-wrapper">
                <ImaAd
                    ref={this.setImaAd}
                    adTag={adTag}
                    audioElement={this.audioElement}
                    onContentPauseRequested={this.onContentPauseRequested}
                    onContentResumeRequested={this.onContentResumeRequested}
                    onAdProgressUpdate={this.onAdProgressUpdate}
                    updateAdDuration={this.updateAdDuration}
                    onAdPlayStarted={this.handleAudioPlay}
                    onAdPlayPause={this.handleAudioPause}
                    onAdPlayComplete={this.handleAdEnded}
                />
                {renderer({
                    isAudioPlaying,
                    onTogglePlayPause: this.onPlayBtnClick,
                    onRefChanged: this.onRenderrRefUpdated,
                    lang: lang,
                })}
                {floatingPlayerRenderer({
                    durationSeconds,
                    onClose: this.stop,
                    isAudioPlaying,
                    audioElement: this.audioElement,
                    isFloatingPlayerVisible: isFloatingPlayerVisible,
                    isSeeking,
                    onTogglePlayPause: this.onPlayBtnClick,
                    adDuration,
                    adPlaybackTime,
                })}
            </div>
        );
    }
}