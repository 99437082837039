import { secondsToHms } from "../../../../siteWidgets/siteWidgetUtils"
import { getTranslation } from "../translations";

interface AdsProgressBarProps {
    adDuration: number
    adPlaybackTime: number
    isAdPlaying: boolean
    lang: string
    onSeeked?: (num: number) => void
}

export class AdsProgressBar extends React.Component<AdsProgressBarProps> {

    render() {
        const { adDuration, adPlaybackTime, isAdPlaying, lang } = this.props;
        const percentage = (adPlaybackTime * 100) / adDuration;
        const rangeBackground = `linear-gradient(90deg, #fec300 ${percentage}%, #cacdce ${percentage}%)`;
        const rangeInputValue = adPlaybackTime >= adDuration ? adDuration : adPlaybackTime;

        return (
            <>
                {isAdPlaying ?
                    <div className="ads-ruler-timeline">
                        <div className="ruler-details">
                            <div className="live-feedback">
                                <div className="dot"></div>
                                <div className="live-text">{getTranslation(lang, "Ad")}</div>
                            </div>
                            <div className="progress-time">{`${secondsToHms(adPlaybackTime)}/${secondsToHms(adDuration)}`}</div>
                        </div>
                        <div className="range-wrap">
                            <input type="range" className="timeline" max={adDuration} value={rangeInputValue} style={{ background: rangeBackground }} />
                        </div>
                    </div >
                    :
                    <>
                        <div className="floating-player-indication-wrapper">
                            <div className="floating-player-dot" />
                            <div className="floating-player-live-indication">{getTranslation(lang, "Now Live")}</div>
                        </div>
                        <input className="floating-player-range" type="range" value="0" readOnly/>
                    </>
                }
            </>
        )
    }
}