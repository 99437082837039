
import { GoogleOAuthProvider } from "@react-oauth/google"
import { PremiumCalcalistPaywallDesktop } from "../../../calcalist/calcalistPaywallForms/desktop/premiumCalcalistPaywallDesktop";
import { PremiumCalcalistPaywallMobile } from "../../../calcalist/calcalistPaywallForms/mobile/premiumCalcalistPaywallMobile"
//this maybe should move to Pixel
const GOOGLE_CLIENT_ID = "1088977389634-sgb74uaa1bhp3k5h9hlqtd4fonmcbl3c.apps.googleusercontent.com";

export const CalcalistUserAuthComponentaWrapper = ({ isMobile }: { isMobile?: boolean }) => {
    return (
        <div className="calcalist-page-auth-wrapper">
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                {isMobile ? <PremiumCalcalistPaywallMobile /> : <PremiumCalcalistPaywallDesktop />}
            </GoogleOAuthProvider>
        </div>
    )
}

CalcalistUserAuthComponentaWrapper.siteScriptName = "CalcalistUserAuthComponentaWrapper"
