import { injectPageComponentaContext } from "widgets/commonComponents/base/injectPageComponentaContext";
import { IYnetnewsArticle } from "./ynetNewsComponenta";
import { SimpleLinkRenderer } from "../../../../commonComponents/inline/hyperLink"
import { DateDisplay } from "../../../../commonComponents/inline/dateDisplay/dateDisplay"
import { separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"

interface TwentyFourSevenItemSlotProps {
    slotItem: IYnetnewsArticle
}

@injectPageComponentaContext()
export class YnetNewsSlotItem extends React.Component<TwentyFourSevenItemSlotProps, {}> {

    public render() {
        const { slotItem } = this.props;

        return (
            <div className="slotView" title={slotItem.title}>
                <div className="dateView">{separateHourFromDateAndFormatToString(slotItem.date, { showDate: false, showHour: true })}</div>
                <div className="slotTitle">
                    <SimpleLinkRenderer href={slotItem.publishedLink} className="titleText"> {slotItem.title}</SimpleLinkRenderer>
                </div>
            </div>
        )
    }
}