import { LinkTargetProperties, BaseLinkTypes } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink"
import { PromoYplusBtn } from "./promoBtn";
import { SiteSimpleImage } from "../../../../commonComponents/inline/siteSimpleImage";
import { getImagesSrc } from "widgets/commonComponents/inline/siteSimpleImage"



interface YplusWithPaperUpperSectionProps {
    tabLogoUrl:string
    isMobile:boolean
    rightTitleLink:string
    rightTitleLinkTarget:LinkTargetProperties
    promoBtnLinkTarget:LinkTargetProperties 
    promoBtnText:string
    promoLink:string
    imagesDomain:string
    categoryId:string
}


interface YplusWithPaperUpperSectionState {
    isUserLogged:boolean
}

export class YplusWithPaperUpperSection extends React.Component<YplusWithPaperUpperSectionProps, YplusWithPaperUpperSectionState>{
    public static siteScriptName = "YplusWithPaperUpperSection";

    constructor(props) {
        super(props as YplusWithPaperUpperSectionProps);
        this.state = {
            isUserLogged: false
        }
    }

    componentDidMount() {
        window && window.tp && window.tp.push(["init", () => {
            const user = window && window.tp && window.tp.pianoId && window.tp.pianoId.getUser();
            if (user) {
                this.setState({ isUserLogged: true })
            }
        }])
    }
    render() {
        const {tabLogoUrl,isMobile,rightTitleLink,rightTitleLinkTarget,promoBtnLinkTarget,promoBtnText,promoLink,imagesDomain, categoryId } = this.props;
        const {isUserLogged} = this.state;
        const isHP = categoryId==="8";
        const userLoggedClass = isUserLogged && !isMobile && isHP ? 'reverse' : '';
       

        return (
            <div className={`upper-container ${userLoggedClass}`}>
                {tabLogoUrl.length && <HyperLinkBuilder className="logoLink" href={rightTitleLink} linkTarget={rightTitleLinkTarget}>
                    <img src={getImagesSrc(imagesDomain,tabLogoUrl)} alt="logo" /></HyperLinkBuilder>}
                {isHP && <div className="newspapper-label"></div>}
                {!isMobile && !isUserLogged  && <PromoYplusBtn link={promoLink} linkTarget={promoBtnLinkTarget} text={promoBtnText} />}
            </div>
        )


    }
}





