import * as classNames from "classnames"
interface CalcalistPaywallCheckoutMultiStepsProgressbarProps {
    steps: string[];
    currentStepIndex: number;
}

const CalcalistPaywallCheckoutMultiStepsProgressbar = (props: CalcalistPaywallCheckoutMultiStepsProgressbarProps) => {

    const { steps, currentStepIndex } = props;


    const setClassesForStep = (index: number) => {
        if (currentStepIndex > index) {
            return "completed"
        } else if (currentStepIndex === index) {
            return "active"
        } else {
            return "disabled"
        }
    }

    const setClassesForDevider = (index: number) => {
        const isFirst = index === 0;
        const isLast = index === steps.length - 2;
        const isBig = isFirst || isLast;
        const isActive = index < currentStepIndex;

        return `devider ${classNames({ "big": isBig, "right": isFirst, "left": isLast, "active": isActive })}`;
    }

    if (!steps || steps.length === 0 || currentStepIndex == 0) {
        return null;
    }

    return (
        <div className="CalcalistPaywallCheckoutMultiStepsProgressbar">
            <div className="progressbar-container">
                <div className="steps-container">
                    {
                        steps.map((step, index) => (
                            <div className="step-container" key={step}>
                                {index < steps.length - 1 && <div className={`divider ${setClassesForDevider(index)}`}></div>}
                                <div className={`step ${setClassesForStep(index)}`}></div>
                                <div className="step-title">{step}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default CalcalistPaywallCheckoutMultiStepsProgressbar;