import { AudioPlayButton, AudioRendererProps, MediaMetadata } from "./audioPlayButton";
import { AodFloatingPlayer } from "./aodFloatingPlayer";
import { ArticleData } from "../../../../articlePage/stateInterfaces";
import { getQueryStringValue } from "../../../widgetsUtils";

interface PodcastPlayerProps {
    audioUrl: string
    title: string
    durationSeconds: number
    className: string
    isMobileWeb?: boolean
    browserDisplayMediaMetaData?: MediaMetadata
    articleLink?: string
    articleData?: ArticleData
    rangeThumbColor?: string
    rangeColor?: string
    rangeProgressColor?: string
}

export function PodcastPlayer(props: PodcastPlayerProps) {
    const { className, title, isMobileWeb, articleLink, articleData, rangeThumbColor, rangeColor, rangeProgressColor } = props;

    return (
        <AudioPlayButton
            {...props}
            isLive={'false'}
            articleData={articleData}
            renderer={(props: AudioRendererProps) => (<ButtonRnederer {...props} className={className} articleData={articleData} />)}
            floatingPlayerRenderer={(props) => (
                <AodFloatingPlayer
                    {...props}
                    title={title}
                    isMobileWeb={isMobileWeb}
                    articleLink={articleLink}
                    rangeThumbColor={rangeThumbColor}
                    rangeColor={rangeColor}
                    rangeProgressColor={rangeProgressColor}
                />
            )}
        />
    )
}

PodcastPlayer.siteScriptName = "PodcastPlayer"

export function ButtonRnederer({ isAudioPlaying, onTogglePlayPause, className, articleData }: AudioRendererProps & { className: string, articleData?: ArticleData }) {

    const audioArticleData = articleData && articleData.specialData && articleData.specialData.audioData
    const category = articleData && articleData.category ? articleData.category.textToShow : "";
    let scriptObj = {}
    
    if (articleData && audioArticleData) {
        scriptObj = {
            id: articleData.articleId,
            name: articleData.title,
            title: `${category} - ${articleData.title}`,
            durationSeconds: audioArticleData.durationSeconds,
            imageUrl: audioArticleData.imageUrl,
            audioUrl: audioArticleData.audioUrl,
            articleLink: articleData.publishedLink
        };
    }

    const scriptString = `window.podcastsData = window.podcastsData || {};
        window.podcastsData["${articleData ? articleData.articleId : ""}"] = ${JSON.stringify(scriptObj)};`

    return (
        articleData && typeof window !== "undefined" && getQueryStringValue("IsApp") && !window.disableAppNativePlayer ?
            <a href={`//podcast/${articleData.articleId}`}>
                <div className="preview-audio-play-button">
                    <div className="audio-play-button-status"></div>
                </div>
                <script type="text/javascript" dangerouslySetInnerHTML={{ __html: scriptString }} />
            </a>
            :
            <button className={className} onClick={onTogglePlayPause} aria-label={isAudioPlaying ? "pause" : "play"} >
                <div className={`audio-play-button-status ${isAudioPlaying ? "pause" : ""}`} ></div>
            </button>
    );
}