import * as axios from "axios"
import { postPaywallLogOut } from "./paywallApiUrls"
import { CustomerAccessData } from "./paywallInterfaces"

export const PAYWALL_LOCAL_STORAGE_KEY = 'paywall'

// wrap this function with try and catch and handle errors
export const AuthLogOut = async () => {
  await axios.post(postPaywallLogOut())
  localStorage.removeItem(PAYWALL_LOCAL_STORAGE_KEY)
}

export const getPaywallSettingsFromLocalStorage = (): CustomerAccessData | null => {
  const customerDetails = localStorage.getItem(PAYWALL_LOCAL_STORAGE_KEY)

  if (!customerDetails) {
    return null
  }

  return JSON.parse(customerDetails)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const grecaptcha: any
declare const window: Window & {
  wcmCaptchaSiteKey: string
}

//from https://medium.com/@dmichaelrobertson/how-to-wait-for-google-recaptcha-token-generation-bb2c05745ed6
export const generateRecaptchaToken = async (action: string): Promise<string> =>
  new Promise((resolve) => {
    grecaptcha.ready(
      () =>
        void (async () => {
          const token = await grecaptcha.execute(window.wcmCaptchaSiteKey, { action });
          resolve(token);
        })(),
    );
  });