import { useEffect, useState } from "react";
import { PremiumPaywallSignModes, AuthStepsEnum } from "../../../../../global/paywall/paywallInterfaces";
import { getAuthHookFactory } from "../../../../../global/paywall/useAuth";

import PremiumCalcalistPaywallDesktopSigninFinish from "./signin/premiumCalcalistPaywallDesktopSigninFinish";
import { PremiumCalcalistPaywallDesktopSigninEmail } from "./signin/premiumCalcalistPaywallDesktopSigninEmail";
import { PremiumCalcalistPaywallDesktopSigninPassword } from "./signin/premiumCalcalistPaywallDesktopSigninPassword"
 

interface PremiumCalcalistPaywallDesktopSigninProps {
  email: string
  password: string
  agreesTerms: boolean
  setEmail: (email: string) => void
  setPassword: (password: string) => void
  setAgreesTerms: (agreesTerms: boolean) => void
  setMode: (mode: PremiumPaywallSignModes) => void
}

export const PremiumCalcalistPaywallDesktopSignin = (props: PremiumCalcalistPaywallDesktopSigninProps) => {
  const { email, setEmail, password, setPassword, setMode, agreesTerms, setAgreesTerms } = props
  const {
    authError,
    step,
    loginWithGoogle,
    loginWithApple,
    thirdPartyLogin,
    sendEmailForLogin,
    submitPassword,
    resetAuth,
  } = getAuthHookFactory("login")();

  useEffect(() => {
    console.log(authError, step)
  }, [authError, resetAuth, step])

  const sendMail = () => {
    sendEmailForLogin(email, agreesTerms)
  }

  const sendPassword = () => {
    submitPassword(password)
  }

  const onReset = () => {
    resetAuth()
  }

  const onThirdPartyLogin = (isNewCustomer: boolean) => { 
    thirdPartyLogin(isNewCustomer)
  }

  switch (step) {
    case AuthStepsEnum.EMAIL:
      return (
        <PremiumCalcalistPaywallDesktopSigninEmail
        email={email}
        setEmail={setEmail}
        setMode={setMode}
        loginWithGoogle={loginWithGoogle}
        loginWithApple={loginWithApple}
        sendMail={sendMail}
        authError={authError}
        />
      )

    case AuthStepsEnum.PASSWORD:
      return (
        <PremiumCalcalistPaywallDesktopSigninPassword
          email={email}
          password={password}
          setPassword={setPassword}
          onContinue={sendPassword}
          onBack={onReset}
          sendMail={sendMail}
          authError={authError}
          setEmail={setEmail}
          
        />
      )

      case AuthStepsEnum.CONFIRM_TERMS:
        // if its sign in, we dont need to confirm terms
        onThirdPartyLogin(false)
        return null

      case AuthStepsEnum.FINISH:
        // setTimeout(() => { window.location.reload() }, 2000)
        return <PremiumCalcalistPaywallDesktopSigninFinish />

    default:
      return null
  }
};
