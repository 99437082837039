import { useEffect } from "react";
import { getAuthHookFactory } from "../../../../../global/paywall/useAuth";
import { AuthStepsEnum, PremiumPaywallSignModes } from "../../../../../global/paywall/paywallInterfaces";
import { PremiumCalcalistPaywallDesktopSignupPassword } from "./signup/premiumCalcalistPaywallDesktopSignupPassword";
import { PremiumCalcalistPaywallDesktopSignupConfirmTerms } from "./signup/premiumCalcalistPaywallDesktopSignupConfirmTerms"
import PremiumCalcalistPaywallDesktopSignupFinish from "./signup/premiumCalcalistPaywallDesktopSignupFinish"
import { PremiumCalcalistPaywallDesktopSignupEmail } from "./signup/premiumCalcalistPaywallDesktopSignupEmail";


interface PremiumCalcalistPaywallDesktopSignupProps {
  email: string
  password: string
  agreesTerms: boolean
  setEmail: (email: string) => void
  setPassword: (password: string) => void
  setAgreesTerms: (agreesTerms: boolean) => void
  setMode: (mode: PremiumPaywallSignModes) => void
}

export const PremiumCalcalistPaywallDesktopSignup = (props: PremiumCalcalistPaywallDesktopSignupProps) => {

  const { email, setEmail, password, setPassword, setMode, agreesTerms, setAgreesTerms } = props

  const {
    authError,
    step,
    loginWithGoogle,
    loginWithApple,
    thirdPartyLogin,
    sendEmailForLogin,
    submitPassword,
    resetAuth,
  } = getAuthHookFactory("signup")();

  useEffect(() => {
    console.log(authError, step)
  }, [authError, resetAuth, step])

  const sendMail = () => {
    sendEmailForLogin(email, agreesTerms)
  }

  const sendPassword = () => {
    submitPassword(password)
  }

  const onReset = () => {
    resetAuth()
  }

  const onThirdPartyLogin = (isNewCustomer: boolean) => { 
    thirdPartyLogin(isNewCustomer)
  }

  switch (step) {
    case AuthStepsEnum.EMAIL:
      return (
        <PremiumCalcalistPaywallDesktopSignupEmail
        email={email}
        agreesTerms={agreesTerms}
        setEmail={setEmail}
        setAgreesTerms={setAgreesTerms}
        setMode={setMode}
        loginWithGoogle={loginWithGoogle}
        loginWithApple={loginWithApple}
        sendMail={sendMail}
        authError={authError}
        />
      )

    case AuthStepsEnum.PASSWORD:
      return (
        <PremiumCalcalistPaywallDesktopSignupPassword
          email={email}
          password={password}
          setPassword={setPassword}
          onContinue={sendPassword}
          onBack={onReset}
          sendMail={sendMail}
          authError={authError}
        />
      )

      case AuthStepsEnum.CONFIRM_TERMS:
        return (
          <PremiumCalcalistPaywallDesktopSignupConfirmTerms 
            resetAuth={onReset}
            thirdPartyLogin={onThirdPartyLogin}
          />
        )

      case AuthStepsEnum.FINISH:
        // setTimeout(() => { window.location.reload() }, 2000)
        return <PremiumCalcalistPaywallDesktopSignupFinish />

    default:
      return null
  }
};
