interface PremiumPayWallMobileSignupConfirmTermsProps {
  resetAuth: () => void
  thirdPartyLogin: (isNewCustomer: boolean) => void
}

export const PremiumPayWallMobileSignupConfirmTerms = (props: PremiumPayWallMobileSignupConfirmTermsProps) => {
  const {resetAuth, thirdPartyLogin} = props
  return (
    <div>
      <h1>אישור תנאים</h1>
      <button onClick={resetAuth}>Cancel</button>
      <button onClick={() => thirdPartyLogin(true)}>Next</button>
    </div>
  )
}
