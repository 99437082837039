import { useState, useEffect } from "react";
import * as classNames from "classnames"
import { getPersonalCustomerDetails, updatePersonalCustomerDetails } from "../../../../../global/paywall/paywallApiUrls";
import * as axios from "axios";
import { is } from "immutable"





interface CalcalistPaywallAccessDataResponse {
    data: {
        id: number;
        email: string;
        firstName: string | null;
        lastName: string | null;
        personalId: string | null;
        phoneNumber: string | null;
    };
    requestId: string;
    error: any;
}


const CalcalistPaywallMyAccountPersonalDetails = () => {

    const [formData, setFormData] = useState<any>({
        firstname: { id: 2, name: "firstname", type: "text", label: "שם פרטי", order: 1, value: "" },
        email: { id: 1, name: "email", type: "text", label: "משתמש", disabled: true, order: 4, value: "" },
        lastname: { id: 3, name: "lastname", type: "text", label: "שם משפחה", order: 2, value: "" },
        phone: { id: 4, name: "phone", type: "text", label: "מספר טלפון", value: "",order: 3 },
    });

    const [errors, setErrors] = useState({});
    const [generalError, setGeneralError] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const validationSchema = {
        email: {
            required: false,
            message: "שדה חובה",
            validate: (value: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || "כתובת מייל לא תקינה",
        },
        firstname: {
            required: false,
            message: "שדה חובה",
            validate: (value: string) => /^[a-zA-Z\u0590-\u05FF]+$/.test(value) || "שם פרטי יכול להכיל רק אותיות",
        },
        lastname: {
            required: false,
            message: "שדה חובה",
            validate: (value: string) => /^[a-zA-Z\u0590-\u05FF]+$/.test(value) || "שם משפחה יכול להכיל רק אותיות",
        },
        phone: {
            required: true,
            message: "שדה חובה",
            validate: (value: string) => /^05\d{8}$/.test(value) || "מספר טלפון לא תקין",
        },
    };


    const validateField = (name, value) => {
        const schema = validationSchema[name];
        if (!schema) return "";
        if (schema.required) {
            if (typeof value === "boolean" && !value) return schema.message;
            if (typeof value === "string" && !value.trim()) return schema.message;
        }
        if (schema.validate) {
            return typeof schema.validate(value) === "boolean" ? "" : schema.validate(value);
        }
        return "";
    };


    const handleChange = (e) => {
        let { name, value } = e.target;
        setIsSubmitted(false);

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: { ...prevFormData[name], value },
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: validateField(name, value),
        }));

    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        let newErrors = {};
        let isValid = true;

        Object.keys(formData).forEach((key) => {
            const errorMessage = validateField(key, formData[key].value);
            if (errorMessage) {
                newErrors[key] = errorMessage;
                isValid = false;
            }
        });

        setErrors(newErrors);

        if (!isValid) {
            console.log("Form has errors:", newErrors);
            return;
        }

        const response = await updateUserData(formData);
        if (response) {
            console.log("Form submitted successfully:", formData);
            setIsSubmitted(true);
        }
    };


    useEffect(() => {
        async function fetchData() {
            const { data } = await getUserData(getPersonalCustomerDetails());
            setFormData(prevFormData => ({
                ...prevFormData,
                email: { ...prevFormData.email, value: data.email },
                firstname: { ...prevFormData.firstname, value: data.firstName || "" },
                lastname: { ...prevFormData.lastname, value: data.lastName || "" },
                phone: { ...prevFormData.phone, value: data.phoneNumber || "" },
            }));
        }
        fetchData();
    }, [])

    const getUserData = async (url: string): Promise<CalcalistPaywallAccessDataResponse> => {
        try {
            const response = await axios.get<CalcalistPaywallAccessDataResponse>(url);
            return response.data;
        } catch (error) {
            console.error(error);
            setGeneralError("משהו השתבש, נסו שוב מאוחר יותר");
        }
    };

    const updateUserData = async (data) => {

        const updatedDetails = {
            details: {
                firstName: data.firstname.value,
                lastName: data.lastname.value,
                phoneNumber: data.phone.value,
                personalId:'323289769'
            }
        };
        try {
            const response = await axios.post(updatePersonalCustomerDetails(), updatedDetails);
            if (response.status === 200) {
                console.log("Data updated successfully");
                return response.data;
            }
        } catch (error) {
            console.error(error);
            setGeneralError("משהו השתבש, נסו שוב מאוחר יותר");
        }
    };

    return (
        <div className="CalcalistPaywallMyAccountPersonalDetails">
            <div className="personal-details-form">
                <form id="checkout-personal-details" onSubmit={handleSubmit}>
                    {Object.keys(formData).map((field) => {
                        const inputField = formData[field];
                        return <div
                            className={`input-field`}
                            style={{ order: inputField.order }}
                            key={inputField.id}>
                            <label>{inputField.label}</label>
                            <input
                                disabled={inputField.disabled}
                                type={inputField.type}
                                name={inputField.name}
                                value={inputField.value}
                                onChange={handleChange}
                                className={errors[field] ? "error" : ""}
                            />
                            {errors[field] && <span className="input-field-error">{errors[field]}</span>}
                        </div>
                    })}
                </form>
                <div className="general-error">{generalError}</div>
                <button disabled={isSubmitted} form="checkout-personal-details" className="submit-button" type="submit">שמירת שינויים</button>
                {isSubmitted && <div className="success-message">השינויים נשמרו בהצלחה!</div>}
            </div>

        </div>
    );
};

export default CalcalistPaywallMyAccountPersonalDetails;