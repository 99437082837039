import * as axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { postPaywallPurchaseProduct } from "./paywallApiUrls";
import { PaywallPurchaseErrorCodes } from "./paywallInterfaces";

interface ApiResponse {
  url: string;
}

export enum PurchaseStatus {
  Idle = "idle",
  Loading = "loading",
  FormReady = "formReady",
  Error = "error",
  Completed = "completed",
  Cancelled = "cancelled",
}

interface PaymentMessage {
  type: "creditCompleted";
  result: "success" | "error" | "cancel";
}

export const usePurchase = (productUniqueId: string) => {
  const [purchaseStatus, setPurchaseStatus] = useState<PurchaseStatus>(PurchaseStatus.Idle);
  const [error, setError] = useState<string | null>(null);
  const [paymentUrl, setPaymentUrl] = useState<string | null>(null);

  const resetPurchase = useCallback(() => {
    setPurchaseStatus(PurchaseStatus.Idle);
    setError(null);
    setPaymentUrl(null);
  }, []);

  const handlePaymentCompletion = useCallback((message: PaymentMessage): void => {
    if (message.type === "creditCompleted") {
      switch (message.result) {
        case "success":
          setPurchaseStatus(PurchaseStatus.Completed);
          break;
        case "error":
          setPurchaseStatus(PurchaseStatus.Error);
          setError(PaywallPurchaseErrorCodes.PAYMENT_FAILED);
          break;
        case "cancel":
          setPurchaseStatus(PurchaseStatus.Cancelled);
          break;
        default:
          setPurchaseStatus(PurchaseStatus.Error);
          setError(PaywallPurchaseErrorCodes.UNEXPECTED_STATUS);
      }
    }
  }, []);

  useEffect(() => {
    if (paymentUrl) {
      return
    }

    const initiatePurchase = async (): Promise<void> => {
      try {
        setPurchaseStatus(PurchaseStatus.Loading);
        setError(null);
        const response = await axios.post<{ data: ApiResponse }>(postPaywallPurchaseProduct(), { productUniqueId });
        const url = response.data && response.data.data && response.data.data.url
        if (url) {
          setPaymentUrl(url);
          setPurchaseStatus(PurchaseStatus.FormReady);
        } else {
          throw new Error(PaywallPurchaseErrorCodes.INVALID_RESPONSE);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err) {
        setError(err.message || PaywallPurchaseErrorCodes.UNKNOWN_ERROR);
        setPurchaseStatus(PurchaseStatus.Error);
      }
    };

    if (productUniqueId) {
      initiatePurchase();
    }
  }, [productUniqueId, paymentUrl]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data && event.data.type === "creditCompleted") {
        handlePaymentCompletion(event.data);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [handlePaymentCompletion]);

  return {
    purchaseStatus,
    paymentUrl,
    error,
    resetPurchase,
  };
};
