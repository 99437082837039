import { PaywallAuthErrorCodes, PremiumPaywallSignModes } from "../../../../../../global/paywall/paywallInterfaces";


interface PremiumPayWallMobileSignupEmailProps {
  email: string
  agreesTerms: boolean
  setEmail: (email: string) => void
  setAgreesTerms: (agreesTerms: boolean) => void
  setMode: (mode: PremiumPaywallSignModes) => void
  loginWithGoogle: () => void
  loginWithApple: () => void
  sendMail: () => void
  authError: string
}

const TERMS_OF_USE_LINK = 'https://www.calcalist.co.il/home/0,7340,L-3856,00.html';
const PRIVACY_POLICY_LINK = 'https://www.calcalist.co.il/home/0,7340,L-3855,00.html';


const errorMessagesFront: Partial<Record<PaywallAuthErrorCodes, string>> = {
  [PaywallAuthErrorCodes.INVALID_EMAIL]: "כתובת המייל אינה תקינה",
  [PaywallAuthErrorCodes.SIGNUP_NO_AGREEMENT]: `חובה לאשר את <a href=${TERMS_OF_USE_LINK}>תנאי השימוש</a>`,
};

const errorMesssagesServer: Partial<Record<PaywallAuthErrorCodes, string>> = {
  [PaywallAuthErrorCodes.EMAIL_ALREADY_EXISTS]: "כתובת המייל כבר קיימת במערכת",
  [PaywallAuthErrorCodes.FAILED_TO_SEND_OTP]: "אירעה שגיאה במערכת, אנא נסו שוב מאוחר יותר",
}



export const PremiumPayWallMobileSignupEmail = (props: PremiumPayWallMobileSignupEmailProps) => {

  const { email, setEmail, agreesTerms, setAgreesTerms, loginWithApple, loginWithGoogle, setMode, sendMail, authError } = props

  const handleMailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleAgreeChange = (e) => {
    setAgreesTerms(e.target.checked)
  }

  const handleSendMail = () => {
    sendMail()
  }

  return (
    <div className="premium-calcalist-paywall-mobile-signup-email">
      <div className="digital-edition-label">המהדורה הדיגיטלית</div>
      <p className="title">צרו חשבון חדש</p>
      <div className="subtitle">וקראו את המהדורה הדיגיטלית<span className='bold'>שבועיים מתנה!</span></div>
      <div className={`email-signup ${authError && authError.includes('EMAIL') ? 'error' : ''}`}>
        <input value={email} onChange={handleMailChange} type="email" placeholder="הקלידו כתובת מייל" />
        <div className="reset-icon" onClick={() => setEmail('')}></div>
        {authError && authError === PaywallAuthErrorCodes.INVALID_EMAIL && <p className="error-text">{errorMessagesFront[authError]}</p>}
      </div>
      <div className={`terms ${authError && authError === PaywallAuthErrorCodes.SIGNUP_NO_AGREEMENT ? 'error' : ''}`}>
        <div className="inner-container">
          <input type="checkbox" checked={agreesTerms} onChange={handleAgreeChange} />
          <span className="text">מסכים/ה ל<a href={TERMS_OF_USE_LINK}>תנאי השימוש</a>, <a href={PRIVACY_POLICY_LINK}>מדיניות פרטיות</a>, וקבלת פניות שיווקיות כולל טלפוניות, הודעות תפעוליות ימשיכו להישלח בתקופת המנוי גם לאחר הסרתי מרשימת מדוורים</span>
        </div>
        <p className="error-text" dangerouslySetInnerHTML={{ __html: errorMessagesFront[authError] }}></p>
      </div>

      <div className="buttons-container">
        <button className="button-base back" onClick={() => setMode("signUp")}>חזרה</button>
        <button className="button-base continue" onClick={handleSendMail}>המשך</button>
      </div>
      {authError && <p className="error-text" dangerouslySetInnerHTML={{ __html: errorMesssagesServer[authError] }}></p>}


      <div className="separator">
        <div className="register-logo"></div>
        <span className="text">או הירשמו באמצעות</span>
      </div>


      <div className="social-signup">
        <button className="social-button google-btn" onClick={loginWithGoogle}><span>גוגל</span></button>
        <button className="social-button apple-btn" onClick={loginWithApple}><span>אפל</span></button>
      </div>

      <div className="login">
        <div>כבר נרשמתם? <a href="#" onClick={() => setMode("signIn")}>כניסה</a></div>
      </div>
      <div className="newspapper-users" >
        <div><span>טרם נרשמתם אבל מנויים של</span> <span className="calcalist-label"></span>?</div>
        <div className="login"><a href="#" onClick={() => setMode("signIn")}>לחצו כאן להתחברות</a></div>
      </div>
    </div>
  )
}
